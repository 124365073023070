<!-- eslint-disable vue/no-mutating-props -->
<!-- eslint-disable max-len -->
<!-- eslint-disable no-tabs -->
<template>
    <div>
        <!-- Modal -->
        <div class="modal" tabindex="-1" role="dialog" :class="{ 'show': showModal }">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" style="font-family: fantasy;"><i class="bi bi-person me-2"></i>ALTERAR LOGO</h5>
                        <button type="button" class="btn btn-danger" @click="hideModal">
                          <span aria-hidden="true">X</span>
                        </button>
                    </div>
                    <div class="modal-body">
                      <form @submit.prevent="editProfile" class="d-flex flex-column justify-content-center mb-3">
                        <div class="mb-3">
                          <span class="title-partner">
                            {{ this.localNamePartner }}
                          </span>
                        </div>
                        <div class="mb-3">
                          <label for="logo" class="form-label d-flex fw-bold">Alterar logo:</label>
                          <input type="file" accept="image/*" class="form-control" @change="onFileSelected">
                        </div>

                        <button type="submit" class="btn btn-primary" @click="changeLogo"><i class="bi bi-person-circle me-2"></i>Alterar</button>
                      </form>
                      <div>
                        <p class="current-image-description">Imagem atual:</p>
                        <img class="img-logo my-2" :src="convertLinkLogo(this.localUserId)" />
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Loading v-if="isLoading" />
</template>

<!-- eslint-disable no-console -->
<script>
import axios from 'axios';
import Loading from '../molecules/Loading.vue';

export default {
  data() {
    return {
      isLoading: false,
      showModal: false,
      imageLogo: null,
      localUserId: null,
      localNamePartner: null,
    };
  },
  components: { Loading },
  props: {
    userId: String,
  },
  methods: {
    hideModal() {
      this.showModal = false;
      this.localUserId = null;
      this.localNamePartner = null;
    },
    convertLinkLogo(localUserId) {
      const link = `https://api.asemego.com.br/api/parceiros/${localUserId}/logo`;
      return link;
    },
    showModalChangePartnerLogo(userId, partnerName) {
      this.localUserId = userId;
      this.localNamePartner = partnerName;
      this.showModal = true;
    },
    onFileSelected(event) { // Método para lidar com a seleção de arquivo
      // eslint-disable-next-line prefer-destructuring
      this.imageLogo = event.target.files[0];
    },
    changeLogo() {
      this.isLoading = true;
      const formData = new FormData();
      formData.append('logo', this.imageLogo);

      const token = localStorage.getItem('token');
      if (token) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      }

      axios.post(`https://api.asemego.com.br/api/parceiros/${this.localUserId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(() => {
        })
        .catch(() => {
        })
        .finally(() => {
          this.hideModal();
          this.isLoading = false;
        });
    },
  },
};
</script>
<!-- eslint-disable no-tabs -->
<style scoped>
.modal {
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal.show {
  display: block;
}

.modal-dialog {
  margin-top: 10%;
}

.modal-content {
  background-color: #fff;
}

.modal-header {
    background-color: #333342;
    color: #FFF;
    font-weight: bold;
}

.img-asemego {
    width: 240px;
}

.btn-primary {
  background-color: #333342 !important;
  border-color: #333342 !important;
}

.img-logo {
  max-width: 300px !important;
  max-height: 300px !important;
  border: 2px solid #333342;
}

.title-partner {
  text-decoration: underline;
  font-weight: bold;
  font-size: 20px;
}

.current-image-description {
  font-weight: bold;
  text-decoration: underline;
}

</style>
