<!-- eslint-disable max-len -->
<!-- eslint-disable no-tabs -->
<template>
<div>
	<!-- Modal -->
	<div class="modal" tabindex="-1" role="dialog" :class="{ 'show': showModal }">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Senha alterada com sucesso!</h5>
					<button type="button" class="btn btn-danger" @click="hideModal">
						<span aria-hidden="true">X</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="mb-4">
						<img class="img-asemego" src="../../assets/wp/logo.png">
					</div>
					<h4 class="text-center fw-bold">Sua senha foi alterada com sucesso!</h4>
          <h4 class="text-center fw-bold">Prossiga com o seu login!</h4>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    showModalWithMessage() {
      this.showModal = true;
    },
    hideModal() {
      this.showModal = false;
    },
  },
};
</script>
<!-- eslint-disable no-tabs -->
  <style scoped>
  .modal {
    display: none;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .modal.show {
    display: block;
  }

  .modal-dialog {
    margin-top: 10%;
  }

  .modal-content {
    background-color: #fff;
  }

	.modal-header {
		background-color: #333342;
		color: #FFF;
		font-weight: bold;
	}

	.img-asemego {
		width: 240px;
	}
  </style>
