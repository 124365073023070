<!-- eslint-disable no-tabs -->
<!-- eslint-disable max-len -->
<template>
  <form @submit.prevent="doLogin">
    <!-- Email -->
    <div class="form-group mb-3" :class="{ error: v$.form.email.$errors.length }">
      <label class="d-flex text-start" for="email">Seu email:</label>
      <input class="form-control" id="email" placeholder="Digite aqui seu email" type="email" v-model="v$.form.email.$model" autocomplete="email">
      <div class="pre-icon os-icon os-icon-user-male-circle"></div>
          <!-- error message -->
      <div class="input-errors mt-1" v-for="(error, index) of v$.form.email.$errors" :key="index">
        <div class="error-msg d-flex text-start text-danger">
					<i class="bi bi-x-circle me-1"></i>
					Digite um email valido.
				</div>
      </div>
    </div>

    <!-- password -->
    <div class="form-group mb-3" :class="{ error: v$.form.password.$errors.length }">
      <label class="d-flex" for="password">Sua senha:</label>
      <input class="form-control" id="password" name="password" placeholder="Digite aqui sua senha" type="password" v-model="v$.form.password.$model" autocomplete="current-password">
      <div class="pre-icon os-icon os-icon-fingerprint"></div>
          <!-- error message -->
      <div class="input-errors mt-1" v-for="(error, index) of v$.form.password.$errors" :key="index">
        <div class="error-msg d-flex text-start text-danger">
					<i class="bi bi-x-circle me-1"></i>
					Sua senha deve ter no mínimo 6 caracteres.
				</div>
      </div>
    </div>

    <!-- Submit Button -->
    <div class="buttons-w">
      <button :disabled="v$.form.$invalid" class="btn btn-success d-flex w-100 justify-content-center">Login</button>
    </div>

  </form>
  <ErrorModal ref="errorModal"></ErrorModal>
	<Loading v-if="isLoading"></Loading>
</template>

<!-- eslint-disable eqeqeq -->
<script>
import useVuelidate from '@vuelidate/core';
import { required, email, minLength } from '@vuelidate/validators';
import axios from 'axios';
import Loading from '../molecules/Loading.vue';
import ErrorModal from '../modals/ErrorModal.vue';
import { useAuthStore } from '../../store/store';

export default {

  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      isLoading: false,
    };
  },

  validations() {
    return {
      form: {
        email: {
          required,
          email,
        },
        password: {
          required,
          min: minLength(6),
        },
      },
    };
  },

  components: { Loading, ErrorModal },

  methods: {
    doLogin() {
      this.isLoading = true;
      axios
        .post('https://api.asemego.com.br/api/login', {
          email: this.form.email,
          password: this.form.password,
        })
        .then((res) => {
          this.token = res.data.data.token;
          // eslint-disable-next-line prefer-destructuring
          const id = res.data.data.id;
          // eslint-disable-next-line prefer-destructuring
          const role = res.data.data.role;
          const idVindi = res.data.data.id_vindi;
          localStorage.setItem('token', this.token);
          localStorage.setItem('id', id);
          localStorage.setItem('id_vindi', idVindi);
          localStorage.setItem('role', role);
          localStorage.setItem('login', true);

          const authStore = useAuthStore();
          authStore.setLogged(true);

          if (role === '1') {
            authStore.setAdmin(false);
            authStore.setUser(true);
            this.$router.push('/account');
          } else if (role === '56751') {
            authStore.setAdmin(true);
            authStore.setUser(false);
            this.$router.push('/dashboard');
          }
        })
        .catch((err) => {
          this.$refs.errorModal.showModalWithMessage(err.response.data.message);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
