<!-- eslint-disable no-tabs -->
<!-- eslint-disable max-len -->
<template>
<div class="personalbill mt-5">
	<div v-if='this.bills != null' class="only-desktop">
		<h1 class="container text-start">Faturas:</h1>
		<table-component :columns="columns" class="text-start">
			<template v-slot:default>
				<tr v-for="(bill, index) in bills" :key="index">
					<td>{{ bill.id }}</td>
					<td> {{ bill.customer.name }}</td>
					<td> {{ bill.customer.email }}</td>
					<td>R$
						<span v-if="bill.amount == '45.0'">45,00</span>
						<span v-else>25,00</span>
					</td>
					<td>
						<span v-if="bill.status ==	'paid'" style="background-color: green; padding: 5px 5px 5px 5px; font-weight: bold; color: #FFF;">Pago</span>
						<span v-else style="background-color: red; padding: 5px 5px 5px 5px; font-weight: bold; color: #FFF;">Pendente</span>
					</td>
					<td class="buttons d-flex flex-row justify-content-center">
						<a class="btn btn-info" :href="bill.url" target="_blank">Informações</a>
					</td>
				</tr>
			</template>
		</table-component>
	</div>
	<div v-else class="only-desktop">
		<div class="container">
			<div class="card">
				<div class="card-body">
					<img class="img-card" src="../../../assets/ui/not-found-fatura.png" alt="Fatura não encontrada">
					<p class="fw-bold">Não foi encontrada nenhuma fatura na sua conta!</p>
					<p class="fw-bold">Entre em contato com o suporte!</p>
				</div>
			</div>
		</div>
	</div>
	<div class="only-mobile container">
		<span class="fw-bold fs-1">Faturas</span>
		<div class="card d-flex flex-column justify-content-center container my-3" v-for="(bill, index) in bills" :key="index">
			<img src="../../../assets/asemego-logo.png" style="width: 200px;" class="d-flex justify-content-center flex-row align-self-center my-3">
			<div class="d-flex flex-row justify-content-center text-start">
				<span class="fw-bold me-1">Nº Fatura:</span>
				<span>{{ bill.id }}</span>
			</div>
			<div class="d-flex flex-row justify-content-center text-start">
				<span class="fw-bold me-1">Nome:</span>
				<span>{{ bill.customer.name }}</span>
			</div>
			<div class="d-flex flex-row justify-content-center text-start">
				<span class="fw-bold me-1">Email:</span>
				<span>{{ bill.customer.email }}</span>
			</div>
			<div class="d-flex flex-row justify-content-center text-start">
				<span class="fw-bold me-1">Valor:</span>
				<span v-if="bill.amount == '45.0'">45,00</span>
				<span v-else>25,00</span>
			</div>
			<div class="d-flex flex-row justify-content-center text-start bill-mb-resp">
				<span class="fw-bold me-1">Status:</span>
					<span v-if="bill.status ==	'paid'" style="background-color: green; padding: 0px 5px 0px 5px; font-weight: bold; color: #FFF;" class="mb-1">Pago</span>
					<span v-else style="background-color: red; padding: 5px 5px 5px 5px; font-weight: bold; color: #FFF;">Pendente</span>
			</div>
			<span class="mb-2">
				<a class="btn btn-info fw-bold text-white" :href="bill.url" target="_blank">Informações</a>
			</span>
		</div>
	</div>
	<Loading v-if="!isLoading"/>
</div>
</template>

<script>
import axios from 'axios';
import Loading from '../../../components/molecules/Loading.vue';
import TableComponent from '../../../ui/TableComponent.vue';

export default {
  data() {
    return {
      isLoading: false,
      idz: '',
      bills: [],
      columns: [
        { label: 'ID', field: 'id' },
        { label: 'Nome', field: 'name' },
        { label: 'Email', field: 'email' },
        { label: 'Valor', field: 'value' },
        { label: 'Status', field: 'status' },
        { label: 'Ações', field: 'actions' },
      ],
    };
  },

  components: { Loading, TableComponent },

  beforeCreate() {
    this.isLoading = false;
    this.idz = localStorage.getItem('id_vindi');
    const token = localStorage.getItem('token');
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
    axios
      .post('https://api.asemego.com.br/api/faturacliente', {
        id: this.idz,
      })
      .then((res) => {
        this.bills = res.data.data.bills;
      })
      .catch(() => {
      })
      .finally(() => {
        this.isLoading = true;
      });
  },
};
</script>

<!-- eslint-disable no-tabs -->
<style lang="stylus" scoped>
.img-card {
	width: 300px;
	height: 300px;
}

table td, table th {
  border-width: 1px;
  border-color: #333342;
  border-style: solid;
  padding: 5px;
}

tr:hover {
	background-color: lightcoral;
}
.only-desktop
	display: block !important

.only-mobile
	display: none !important

@media screen and (max-width: 768px)
	.only-desktop
		display: none !important
	.only-mobile
		display: block !important

	.card
		-webkit-box-shadow: 10px 10px 16px 1px rgba(0,0,0,0.37);
		-moz-box-shadow: 10px 10px 16px 1px rgba(0,0,0,0.37);
		box-shadow: 10px 10px 16px 1px rgba(0,0,0,0.37);

	.bill-mb-resp {
		margin-bottom: 1rem;
	}
</style>
