<!-- eslint-disable max-len -->
<!-- eslint-disable no-tabs -->
<template>
<div class="schedules-view my-5">

  <h2 class="my-4 fw-bold">Agendamentos disponíveis: <span><button class="btn btn-outline-info btn-mbl" @click="this.stepPage = 1" v-if="this.stepPage > 1"><i class="bi bi-arrow-left ms-1"></i>Voltar</button></span></h2>
  
  <div class="container">
    <div class="schedules-choices row" v-if="this.stepPage == '1' || this.stepPage == 1">
      <div class="col-12 mb-4">
        <div class="card card-choice" @click="this.stepPage = 2">
          <img src="../assets/terapia.jpeg" class="card-image">
          <div class="card-img-overlay">
            <span class="card-body title-card">Terapias</span>
          </div>
        </div>
      </div>
      <div class="col-12 mb-4">
        <div class="card card-choice" @click="this.stepPage = 3">
          <img src="../assets/salas.jpg" class="card-image">
          <div class="card-img-overlay">
            <span class="card-body title-card">Salas</span>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card card-choice" @click="this.stepPage = 4">
          <img src="../assets/espacos.png" class="card-image">
          <div class="card-img-overlay">
            <span class="card-body title-card">Espaços</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="this.stepPage == '2' || this.stepPage == 2">
      <div class="img-partner col-6 col-md-4" v-for="schedule in healthSchedules" :key="schedule.id">
        <router-link :to="{ name: 'SchedulesDetails', params: { id: schedule.id } }">
          <div class="card-convenio">
            <img :src='convertLinkLogo(schedule.id)'>
          </div>
        </router-link>
      </div>
    </div>
    <div class="row" v-if="this.stepPage == '3' || this.stepPage == 3">
      <div class="img-partner col-6 col-md-4" v-for="schedule in roomSchedules" :key="schedule.id">
        <router-link :to="{ name: 'SchedulesDetails', params: { id: schedule.id } }">
          <div class="card-convenio">
            <img :src='convertLinkLogo(schedule.id)'>
          </div>
        </router-link>
      </div>
    </div>
    <div class="row" v-if="this.stepPage == '4' || this.stepPage == 4">
      <div class="img-partner col-6 col-md-4" v-for="schedule in placesSchedules" :key="schedule.id">
        <router-link :to="{ name: 'SchedulesDetails', params: { id: schedule.id } }">
          <div class="card-convenio">
            <img :src='convertLinkLogo(schedule.id)'>
          </div>
        </router-link>
      </div>
    </div>
  </div>
  <Loading v-if="isLoading"/>
</div>
</template>

<!-- eslint-disable max-len -->
<!-- eslint-disable global-require -->
<script>
import axios from 'axios';
import Loading from '../components/molecules/Loading.vue';

export default {
  data() {
    return {
      schedules: [],
      healthSchedules: [],
      roomSchedules: [],
      placesSchedules: [],
      url: 'https://asemego.com.br/api/storage/app/',
      isLoading: false,
      stepPage: 1,
    };
  },
  components: { Loading },
  created() {
    this.getAllTypes();
  },
  methods: {
    convertLinkLogo(id) {
      const link = `https://api.asemego.com.br/api/agendamentos/${id}/imagem`;
      return link;
    },
    getAllTypes() {
      this.isLoading = true;
      axios.get('https://api.asemego.com.br/api/agendamentos')
        .then((res) => {
          this.schedules = res.data.agendamentos;
          const healthSchedules = this.schedules.filter((schedule) => schedule.tipo === 'health');
          this.healthSchedules = healthSchedules;
          const roomSchedules = this.schedules.filter((schedule) => schedule.tipo === 'room');
          this.roomSchedules = roomSchedules;
          const placesSchedules = this.schedules.filter((schedule) => schedule.tipo === 'place');
          this.placesSchedules = placesSchedules;
        })
        .catch(() => {
        }).finally(() => {
          this.isLoading = false;
        });
    },
    getHealthType() {
      this.isLoading = true;
      axios.get('https://api.asemego.com.br/api/agendamentos')
        .then((res) => {
        // Filtrar os agendamentos com schedules.tipo == 'health'
          const healthSchedules = res.data.agendamentos.filter((schedule) => schedule.tipo === 'health');
          // Atribuir os agendamentos filtrados a this.schedules
          this.schedules = healthSchedules;
        })
        .catch(() => {
        // Lidar com erros
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<!-- eslint-disable no-tabs -->
<style scoped>
img {
  height: 200px;
  width: 300px;
  border-radius: 6px;
  z-index: 1;
  -webkit-box-shadow: 0px 1px 11px 5px rgba(0,0,0,0.36);
  -moz-box-shadow: 0px 1px 11px 5px rgba(0,0,0,0.36);
  box-shadow: 0px 1px 11px 5px rgba(0,0,0,0.36);
  transition: opacity 0.3s ease, box-shadow 0.3s ease;
  opacity: 0.5 !important;
}
 img:hover {
	 opacity: 1 !important;
}
 .img-partner {
	 margin-bottom: 1rem !important;
}
 .img-large {
	 width: 300px !important;
}
 .schedules-view {
	 height: 100dvh !important;
}
 .card {
	 border: none !important;
}
 .card-image {
	 width: 100% !important;
	 opacity: 0.2 !important;
}
 .card-img-overlay > span {
	 font-size: 100px;
}
 .card-choice:hover {
	 transform: scale(1.05);
	 cursor: pointer;
}
 .card-choice {
	 transition: all 0.5s;
}
 @media screen and (max-width: 768px) {
	 .schedules-view {
		 height: 100% !important;
	}
	 .schedules-view .img-partner {
		 margin-bottom: 1rem !important;
	}
	 .schedules-view img {
		 height: 120px;
		 width: 150px;
		 opacity: 1 !important;
	}
	 .schedules-view .img-large {
		 height: 100px !important;
		 width: 120px !important;
	}
	 .schedules-view .card-image {
		 opacity: 0.2 !important;
		 width: 100% !important;
	}
	 .schedules-view .card-img-overlay > span {
		 font-size: 60px;
	}
	 .schedules-view .btn-mbl {
		 margin-top: 1rem;
		 margin-bottom: 1rem;
	}
}

</style>
