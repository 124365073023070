<!-- eslint-disable max-len -->
<!-- eslint-disable no-tabs -->
<template>
<div class="news-view my-4">
    <div class="container">
        <h2>Novidades ASEMEGO!</h2>
        <div class="feed-news">
            <div class="feed-news-post">
                <div class="card d-flex flex-column px-5">
                    <h4 class="text-start mt-3">Quais as vantagens de investir em uma associação?</h4>
                    <p class="text-start">Provavelmente você já deve ter ouvido falar sobre a importância de construir uma boa rede de contatos, não é? Isso é o que chamamos de networking, um termo em inglês que utilizamos para nos referir ao compartilhamento de informações
                        ou serviços entre pessoas.</p>
                    <p class="text-start">E já pensou como seria se você tivesse vínculos com uma empresa, associação ou grupos que ofereçam bons resultados na sua vida profissional? É sobre isso que iremos comentar ao longo desta matéria. Continue acompanhando e faça uma
                        boa leitura!</p>
                    <h4 class="text-start">Uma associação colabora para o seu bem-estar e progresso</h4>
                    <p class="text-start">Vantagens econômicas, conquistas, representatividade e garantia de direitos relacionados à determinada área, defesa mútua de interesses e outras afinidades são alguns fatores que definem a relação com uma associação. Portanto, é dever
                        da empresa oferecer apoio e somar positivamente com as atividades dos associados.</p>
                    <p class="text-start">Com isso, a interação entre os beneficiários e associação promove resultados significativos para ambas as partes. Uma vez que um se identifica com a missão, valores e propósitos e o outro oferece suporte para crescerem e conquistarem
                        direitos, benefícios e, acima de tudo, na defesa dos seus direitos.</p>
                    <h4 class="text-start">Quais são as oportunidades geradas através da associação?</h4>
                    <p class="text-start">Benefícios exclusivos e clube de vantagens, assessoria jurídica, espaço multiprofissional e muito mais. Essas são algumas vantagens que os associados podem receber!</p>
                    <p class="text-start">Se você é um servidor público da educação e ainda não conhece os benefícios que a ASEMEGO pode te proporcionar, seja para o seu negócio ou demandas individuais, entre em contato conosco para explicarmos detalhadamente.</p>
                    <p class="text-start mb-3">Este conteúdo foi útil para você? Compartilhe com os amigos e colegas de profissão. Fique atento aos nossos conteúdos para não perder nossas atualizações. Nos acompanhe no Instagram e confira nossas lives, debates importantes e dicas
                        especiais. Te esperamos lá!</p>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<!-- eslint-disable no-tabs -->
<!-- eslint-disable max-len -->
<style scoped>
h2, h4 {
	color: #333342;
	margin-bottom: 1rem;
}

.card {
	background: #F2F2F2;
	box-shadow: rgba(0,0,0,0.4) 0px 2px 4px, rgba(0,0,0,0.3) 0px 7px 13px -3px;
}

</style>
