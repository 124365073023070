<!-- eslint-disable max-len -->
<template>
<div class="about-view">
  <div class="title-view">
    <h1 class="my-5">Sobre a ASEMEGO</h1>
  </div>
  <div class="container">
    <AccordionAbout  class="mb-4"/>
    <div class="advantages-card d-flex flex-column">
      <div class="advantages-associate">
        <div class="advantage-content">
          <div class="advantage-title fw-bold mt-2 mb-3 text-start">
            <span>VANTAGENS DE SER ASSOCIAR À ASEMEGO</span>
          </div>
          <ul class="text-start">
            <li><span>Clube de Vantagens e descontos em vários estabelecimentos</span></li>
            <li><span>Assessoria jurídica em âmbito educacional</span></li>
            <li><span>Coworking para auxiliar os diretores nas demandas externas, bem como os demais servidores que procuram um espaço que atenda suas necessidades para auxiliá-los em seu trabalho</span></li>
            <li><span>Espaço para divulgação dos empreendimentos dos servidores (Virtual: Mídias Digitais e Presencial: feirinha do servidor)</span></li>
            <li><span>Salas de Reuniões</span></li>
            <li><span>Promoção intelectual para os associados buscando ferramentas para o crescimento pessoal e profissional por meio da oferta de formação continuada</span></li>
            <li><span>Espaço de Bem-estar e beleza</span></li>
            <li><span>Espaço Multiprofissional</span></li>
            <li><span>Happy Hours do servidor</span></li>
            <li><span>Espaço Gourmet</span></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<WhatsappButton />
</template>

<script>
import WhatsappButton from '../components/atoms/WhatsappButton.vue';
import AccordionAbout from '../ui/AccordionAbout.vue';

export default {
  name: 'AboutPage',
  components: { WhatsappButton, AccordionAbout },

};
</script>

<style scoped>
.description-session {
  margin-bottom: 1rem;
}

.description-title {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0;
}

.advantages-card {
  background-color: #f2f2f2;
  border-radius: 0.5rem;
  padding: 1rem 1rem;
  margin-bottom: 2rem;
}
</style>
