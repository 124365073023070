<!-- eslint-disable max-len -->
<template>
  <div class="convenio-details-view py-3">
    <div class="container">
      <div class="title-top d-flex flex-row justify-content-center">
        <router-link to="/partners" class="btn btn-danger mobile-back-button mb-3">
          <i class="bi bi-arrow-left"></i>
          Voltar
        </router-link>
      </div>
      <div>
        <div class="d-flex justify-content-between">
          <div class="d-flex">
            <router-link to="/partners" class="btn btn-danger back-button">
              <i class="bi bi-arrow-left"></i>
              Voltar
            </router-link>
          </div>
          <div></div>
        </div>
      </div>
      <div class="card-partner-informations">
        <div class="container">
          <div class="container-body">
            <img class="img-logo-partner-details my-2" :src="convertLinkLogo(partner.id)" />
            <div class="d-flex flex-row justify-content-center">
              <div class="card-tag px-2 py-2">
                <span v-if="partner.tag == 'others'">Outros</span>
                <span v-else-if="partner.tag == 'health'">Saúde e Bem-estar</span>
                <span v-else-if="partner.tag == 'tech'">Tecnologia</span>
                <span v-else-if="partner.tag == 'industry'">Indústria</span>
                <span v-else-if="partner.tag == 'creativity'">Criatividade</span>
                <span v-else-if="partner.tag == 'education'">Educação</span>
                <span v-else-if="partner.tag == 'business'">Negócios e Finanças</span>
                <span v-else-if="partner.tag == 'entertainment'">Artes e Entretenimento</span>
                <span v-else-if="partner.tag == 'Sustainability'">Sustentabilidade</span>
                <span v-else-if="partner.tag == 'food'">Alimentação e Gastronomia</span>
                <span v-else-if="partner.tag == 'tourism'">Viagens e Turismo</span>
                <span v-else>Categoria nova</span>
              </div>
            </div>
            <div class="d-flex flex-column justify-content-center">
              <p class="fw-bold">
                <span>{{ partner.nome }}</span>
              </p>
              <div v-if="partner.descricao != null" class="d-flex justify-content-center w-100">
                <p class="descripton-partner text-center">
                  {{ partner.descricao }}
                </p>
              </div>
            </div>
            <div class="d-flex flex-row justify-content-center my-5 organize-mobile">
              <a
                v-if="partner.telefone !== null"
                class="btn btn-secondary me-2"
                :href="partner.telefone"
                target="_blank"
              >
                <i class="bi bi-telephone-fill me-1"></i>
                Telefone
              </a>
              <a
                v-if="partner.telefone_2 !== null"
                class="btn btn-secondary me-2"
                :href="partner.telefone_2"
                target="_blank"
              >
                <i class="bi bi-telephone-fill me-1"></i>
                Telefone
              </a>
              <a
                v-if="partner.whatsapp !== null"
                class="btn btn-success me-2"
                :href="partner.whatsapp"
                target="_blank"
              >
                <i class="bi bi-telephone me-1"></i>
                Whatsapp
              </a>
              <a
                v-if="partner.whatsapp_2 !== null"
                class="btn btn-success me-2"
                :href="partner.whatsapp_2"
                target="_blank"
              >
                <i class="bi bi-telephone me-1"></i>
                Whatsapp
              </a>
              <a
                v-if="partner.youtube !== null"
                class="btn btn-instagram me-2"
                :href="partner.youtube"
                target="_blank"
              >
                <i class="bi bi-youtube me-1"></i>
                Youtube
              </a>
              <a
                v-if="partner.facebook !== null"
                class="btn btn-primary me-2"
                :href="partner.facebook"
                target="_blank"
              >
                <i class="bi bi-facebook me-1"></i>
                Facebook
              </a>
              <a
                v-if="partner.instagram !== null"
                class="btn btn-instagram me-2"
                :href="partner.instagram"
                target="_blank"
              >
                <i class="bi bi-instagram me-1"></i>
                Instagram
              </a>
              <a
                v-if="partner.instagram_2 !== null"
                class="btn btn-instagram me-2"
                :href="partner.instagram_2"
                target="_blank"
              >
                <i class="bi bi-instagram me-1"></i>
                Instagram
              </a>
              <a
                v-if="partner.website !== null"
                class="btn btn-secondary me-2"
                :href="partner.website"
                target="_blank"
              >
                <i class="bi bi-globe me-1"></i>
                Website
              </a>
            </div>
            <div v-if="partner.promocoes" class="flex-row d-flex justify-content-center my-3">
              <div class="card card-partner">
                <div class="d-flex justify-content-around">
                  <div class="py-2 px-2">
                    <img class="card-img-partner" src="../assets/voucher.png" />
                  </div>
                  <div class="d-flex flex-column justify-content-center me-2">
                    <span class="fw-bold" style="color: white">{{ partner.promocoes }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column justify-content-center container my-5">
              <img v-if="partner.imagem_1" class="my-2" :src="convertLinkImage1(partner.id)"/>
              <img v-if="partner.imagem_2" class="my-2" :src="convertLinkImage2(partner.id)"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      partner: {
        imagens: [],
      },
    };
  },
  props: {
    partnerId: {
      type: String,
      required: true,
    },
  },
  beforeCreate() {
    axios
      .get(`https://api.asemego.com.br/api/parceiros/${this.partnerId}`)
      .then((res) => {
        // eslint-disable-next-line prefer-destructuring
        this.partner = res.data;
      })
      .catch(() => {})
      .finally(() => {
        // eslint-disable-next-line no-console
      });
  },
  methods: {
    convertLinkLogo(id) {
      const link = `https://api.asemego.com.br/api/parceiros/${id}/logo`;
      return link;
    },
    convertLinkImage1(id) {
      const link = `https://api.asemego.com.br/api/parceiros/${id}/imagem?imagem=1`;
      return link;
    },
    convertLinkImage2(id) {
      const link = `https://api.asemego.com.br/api/parceiros/${id}/imagem?imagem=2`;
      return link;
    },
  },
};
</script>

<style scoped>
.convenio-details-view {
  background-color: white;
}


.descripton-partner {
  width: 50%;
  
}

.btn {
  color: white !important;
  height: 100%;
}

.btn-instagram {
  background-color: red;
}

.btn-instagram:hover {
  background-color: red;
}

.btn-danger {
  height: 35px;
}

.back-button {
  display: block !important;
}

.mobile-back-button {
  display: none !important;
}

.card-tag {
  border-radius: 6px;
  background-color: rgba(51, 51, 66, 1);
  font-weight: bold;
  color: white;
  width: fit-content;
}
.card-partner {
  background: linear-gradient(to right, #eeb12f, #b38728, #eeb12f, #b38728, #eeb12f);
  border: none;
}

.card-img-partner {
  width: 40px !important;
  height: 40px !important;
}

.img-logo-partner-details {
  border-radius: 6px;
  border: none !important;
  -webkit-box-shadow: 0px 1px 11px 5px rgba(0,0,0,0.36);
  -moz-box-shadow: 0px 1px 11px 5px rgba(0,0,0,0.36);
  box-shadow: 0px 1px 11px 5px rgba(0,0,0,0.36);
  max-height: 300px;
}

@media screen and (max-width: 576px) {
  .title-top {
    flex-direction: column !important;
    justify-content: center !important;
    align-content: center;
    align-items: center;
    width: 100% !important;
  }
  .btn-danger {
    width: 100% !important;
  }

  .back-button {
    display: none !important;
  }

  .mobile-back-button {
    display: block !important;
    width: 100%;
  }

  .card-img-partner {
    width: 40px !important;
    height: 40px !important;
  }

  .card-tag {
    width: fit-content;
  }
  .img-logo-partner-details {
    width: auto;
    height: 200px;
  }

  .organize-mobile {
    display: flex !important;
    justify-content: center !important;
    flex-direction: column !important;
  }

  .btn {
    width: 100% !important;
  }

  .organize-mobile > * {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 767px) {
  .title-top {
    flex-direction: column !important;
    justify-content: center !important;
    align-content: center;
    align-items: center;
    width: 100% !important;
  }
  .btn-danger {
    width: 100% !important;
  }

  .back-button {
    display: none !important;
  }

  .mobile-back-button {
    display: block !important;
    width: 100%;
  }

  .organize-mobile {
    display: flex !important;
    justify-content: center !important;
    flex-direction: column !important;
  }

  .btn {
    width: 100% !important;
  }

  .organize-mobile > * {
    margin-top: 1rem;
  }

}
</style>
