import { createApp } from 'vue';
import { createPinia } from 'pinia';
import VueTheMask from 'vue-the-mask';
import App from './App.vue';
import router from './router';
import 'cropperjs/dist/cropper.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'tailwindcss/tailwind.css';
import Cropper from 'vue-cropperjs';

createApp(App).use(createPinia()).use(router).use(VueTheMask).component('Cropper', Cropper)
  .mount('#app');
