<!-- eslint-disable no-tabs -->
<!-- eslint-disable max-len -->

<template>
<div class="form-signup">
  <form @submit.prevent="createAccount">

    <div v-if="stepCreate == '1'">
      <!-- Email Row -->
      <div class="form-group">
        <label for="" class="d-flex text-start mt-1"> Digite seu email:</label>
        <input class="form-control" placeholder="Digite seu email" type="email" v-model="v$.form.email.$model">
        <div class="pre-icon os-icon os-icon-email-2-at2"></div>
        <!-- Error Message -->
          <div class="input-errors mt-1" v-for="(error, index) of v$.form.email.$errors" :key="index">
            <div class="error-msg d-flex text-start text-danger">
              <i class="bi bi-x-circle me-1"></i>
                Digite um endereço de email valido!
              </div>
          </div>
      </div>
      <button v-if="stepCreate == '1'" class="btn btn-success d-flex w-100 justify-content-center my-3" @click="vefiryAccount">Verificar</button>
    </div>

    <div v-if="stepCreate == '2'">

      <!-- First and Last Name Row -->
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="" class="d-flex text-start"> Nome completo:</label>
            <input class="form-control" placeholder="Digite seu nome completo" type="text" v-model="v$.form.fullName.$model" maxlength="20" required>
            <div class="pre-icon os-icon os-icon-user-male-circle"></div>
            <!-- Error Message -->
            <div class="input-errors mt-1" v-for="(error, index) of v$.form.fullName.$errors" :key="index">
              <div class="error-msg d-flex text-start text-danger">
                <i class="bi bi-x-circle me-1"></i>
                O nome deve conter entre 5 e 20 caracteres.
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Password Row -->
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="" class="d-flex text-start mt-1"> Cadastrar senha:</label>
            <input class="form-control" placeholder="Digite sua senha" type="password" v-model="v$.form.password.$model" minlength="6" required>
            <div class="pre-icon os-icon os-icon-fingerprint"></div>
            <!-- Error Message -->
            <div class="input-errors" v-for="(error, index) of v$.form.password.$errors" :key="index">
              <div class="error-msg d-flex text-start text-danger mt-1">
                <i class="bi bi-x-circle me-1"></i>
                Mínimo: 6 dígitos!
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-6">
          <div class="form-group">
            <input class="form-control address-input" type="text" placeholder="Endereço" v-model="v$.form.address.$model" required/>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <input class="form-control number-input" type="text" placeholder="Número" v-model="v$.form.number.$model" required/>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <div class="form-group">
            <input class="form-control details-input" type="text" placeholder="Detalhes adicionais" v-model="v$.form.additional_details.$model" required/>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <input class="form-control cep-input" type="text" placeholder="CEP" v-mask="'#####-###'" v-model="v$.form.zipcode.$model" required/>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-4">
          <div class="form-group">
            <input class="form-control bairro-input" type="text" placeholder="Bairro" v-model="v$.form.neighborhood.$model" required/>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <input class="form-control city-input" type="text" placeholder="Cidade" v-model="v$.form.city.$model" required/>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <input type="text" class="form-control" v-model="v$.form.state.$model" placeholder="Estado" required/>
          </div>
        </div>
      </div>
      <div class="row mt-3  ">
        <div class="col-6">
          <input class="form-control" type="text" v-mask="'+## (##) #####-####'" placeholder="Celular: +XX (XX) XXXXX-XXXX" v-model="v$.form.phone.$model" required/>
        </div>
        <div class="col-6">
          <input class="form-control" type="text" v-mask="'##/##/####'" placeholder="Data de nascimento" v-model="v$.form.birthday.$model" required/>
        </div>
      </div>
      <!-- Submit Button -->
      <div class="buttons-w">
        <button type="submit" class="btn btn-success d-flex justify-content-center w-100 mt-3 mb-2">Cadastrar</button>
      </div>
    </div>
  </form>
  <Loading v-if="isLoading"></Loading>
</div>
</template>

<script>
import axios from 'axios';
import useVuelidate from '@vuelidate/core';
import {
  required, email, minLength,
} from '@vuelidate/validators';
import { VueMaskDirective } from 'vue-the-mask';
import Loading from '../molecules/Loading.vue';

export default {

  setup() {
    return { v$: useVuelidate() };
  },

  directives: {
    mask: VueMaskDirective,
  },

  components: { Loading },

  data() {
    return {
      isLoading: false,
      stepCreate: 1,
      form: {
        fullName: '',
        email: '',
        password: '',
        address: '',
        number: '',
        additional_details: '',
        zipcode: '',
        neighborhood: '',
        city: '',
        state: '',
        phone: '',
        birthday: '',
      },
    };
  },

  validations() {
    return {
      form: {
        fullName: { required },
        email: { required, email },
        password: { required, min: minLength(6) },
        address: { required, min: minLength(3) },
        number: { required, min: minLength(1) },
        additional_details: { required, min: minLength(1) },
        zipcode: { required, min: minLength(6) },
        neighborhood: { required, min: minLength(2) },
        city: { required, min: minLength(3) },
        state: { required, min: minLength(4) },
        phone: { required, min: minLength(10) },
        birthday: { required, min: minLength(8) },
      },
    };
  },

  methods: {
    vefiryAccount(e) {
      e.preventDefault();
      this.isLoading = true;
      axios
        .post('https://api.asemego.com.br/api/verify', {
          email: this.form.email,
        })
        .then((res) => {
          const codeReturn = res.data.data.code;
          if (codeReturn === 0) {
            const UserArray = res.data.data.Usuario[0];
            this.form.fullName = UserArray.name;
            if (this.form.fullName.length > 20) {
              this.form.fullName = this.form.fullName.substring(0, 20);
            }
            this.form.phone = UserArray.phones[0].number;
            this.form.address = UserArray.address.street;
            this.form.number = UserArray.address.number;
            this.form.additional_details = UserArray.address.additional_details;
            this.form.zipcode = UserArray.address.zipcode;
            this.form.neighborhood = UserArray.address.neighborhood;
            this.form.city = UserArray.address.city;
            this.form.state = UserArray.address.state;
            this.form.birthday = UserArray.metadata.birthday_vindi;
            this.stepCreate = 2;
          } else if (codeReturn === 1) {
            this.$emit('backStep');
          } else {
            this.$emit('voltarStep');
          }
        })
        .catch(() => {
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    createAccount(e) {
      e.preventDefault();
      this.isLoading = true;
      if (this.form.fullName.length > 20) {
        this.form.fullName = this.form.fullName.substring(0, 20);
      }
      axios.post('https://api.asemego.com.br/api/usuarios', {
        name: this.form.fullName,
        email: this.form.email,
        password: this.form.password,
        street: this.form.address,
        number: this.form.number,
        additional_details: this.form.additional_details,
        zipcode: this.form.zipcode,
        neighborhood: this.form.neighborhood,
        city: this.form.city,
        state: this.form.state,
        phone: this.form.phone,
        birthday: this.form.birthday,
        role: '1',
      }).then(() => {
        this.$emit('accountCreated');
      })
        .catch(() => {
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
