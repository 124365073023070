<template>
  <a class="button-whatsapp" href='https://api.whatsapp.com/send/?phone=556236246665&text&type=phone_number&app_absent=0' target="_blank">
    <i class="bi bi-whatsapp"></i>
  </a>
</template>

<style scoped>
.button-whatsapp {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.bi-whatsapp {
  position: relative;
  top: 8px;
}
</style>