<!-- eslint-disable max-len -->
<!-- eslint-disable no-tabs -->
<template>
    <div>
        <!-- Modal -->
        <div class="modal" tabindex="-1" role="dialog" :class="{ 'show': showModal }">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" style="font-family: fantasy;"><i class="bi bi-person-fill-dash me-2"></i>REMOVER PARCEIRO</h5>
                        <button type="button" class="btn btn-danger" @click="hideModal">
                            <span aria-hidden="true">X</span>
                        </button>
                    </div>
                    <div class="modal-body">
                      <h5>Deseja remover o parceiro <span class="fw-bold me-2" style="background-color: red; color: white;">{{ partnerName }}</span>?</h5>
                      <div class="d-flex flex-row justify-content-center mt-4">
                        <button class="btn btn-danger me-4" @click="confirmDeletePartner">Remover</button>
                        <button class="btn btn-info" @click="hideModal">Cancelar</button>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>

<!-- eslint-disable no-console -->
<script>

export default {
  data() {
    return {
      showModal: false,
    };
  },
  props: {
    partnerId: null,
    partnerName: String,
  },
  methods: {
    hideModal() {
      this.showModal = false;
    },
    showModalToDelete() {
      this.showModal = true;
    },
    confirmDeletePartner() {
      this.$emit('deletePartner', this.partnerId);
    },
  },
};
</script>
<!-- eslint-disable no-tabs -->
<style scoped>
.modal {
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal.show {
  display: block;
}

.modal-dialog {
  margin-top: 10%;
}

.modal-content {
  background-color: #fff;
}

.modal-header {
    background-color: #333342;
    color: #FFF;
    font-weight: bold;
}

.img-asemego {
    width: 240px;
}

.btn-primary {
  background-color: #333342 !important;
  border-color: #333342 !important;
}
</style>
