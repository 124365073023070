<!-- eslint-disable no-unused-expressions -->
<!-- eslint-disable no-tabs -->
<!-- eslint-disable max-len -->
<template>
<div class="forgot-password-view">
	<div v-if='stepForgot == 1'>
		<div class="mb-3">
			<label class="form-label d-flex" for="inputRecoveryEmail">Endereço de email:</label>
			<input class="form-control" id="inputRecoveryEmail" type="email" placeholder="Inserir email" required="true" aria-required="true" v-model="v$.emailForgot.$model"/>
			<div class="input-errors mt-1" v-for="(error, index) of v$.emailForgot.$errors" :key="index">
				<div class="error-msg d-flex text-start text-danger mb-1">
					<i class="bi bi-x-circle me-1"></i>
					Digite um endereço de email valido!
				</div>
			</div>
		</div>
		<div class="mb-1">
			<button class="btn btn-primary mb-2 w-100" @click="sendRecoveryToEmail">Recuperar senha</button>
		</div>
	</div>
	<div v-if="stepForgot == 2">
		<div class="insert-token d-flex flex-column">
			<p>O token foi enviado para o email:
				<span class="fw-bold">
					{{ emailForgot }}.
				</span>
			</p>
			<h6 class="text-start">Token:</h6>
			<input type="text" placeholder="Insira aqui o token" id="inputToken" required="true" aria-required="true" class="form-control mb-2" v-model="v$.emailToken.$model" v-mask="'######'">
			<div class="input-errors mt-1" v-for="(error, index) of v$.emailToken.$errors" :key="index">
				<div class="error-msg d-flex text-start text-danger mb-1">
					<i class="bi bi-x-circle me-1"></i>
					Digite os 4 dígitos do token!
				</div>
			</div>
			<h6 class="text-start mb-2">Nova senha:</h6>
			<input type="password" placeholder="Insira sua nova senha" id="inputPassword" required="true" aria-required="true" class="form-control mb-2" v-model="v$.newPassword.$model">
			<div class="input-errors mt-1" v-for="(error, index) of v$.newPassword.$errors" :key="index">
				<div class="error-msg d-flex text-start text-danger mb-1">
					<i class="bi bi-x-circle me-1"></i>
					A senha deve ter no mínimo 6 dígitos!
				</div>
			</div>
			<button class="btn btn-success mb-2" @click="sendNewPassword">Alterar senha</button>
		</div>
	</div>
</div>
<Loading v-if="isLoading" />
<WrongEmailModal ref="wrongEmail"/>
</template>

<!-- eslint-disable no-tabs -->
<script>
import axios from 'axios';
import { VueMaskDirective } from 'vue-the-mask';
import useVuelidate from '@vuelidate/core';
import {
  required, minLength, email,
} from '@vuelidate/validators';
import Loading from '../molecules/Loading.vue';
import WrongEmailModal from '../modals/WrongEmailModal.vue';

export default {

  components: { Loading, WrongEmailModal },

  directives: {
    mask: VueMaskDirective,
  },

  setup() {
    return { v$: useVuelidate() };
  },

  validations() {
    return {
      emailToken: { required, min: minLength(4) },
      newPassword: { required, min: minLength(6) },
      emailForgot: { required, email },
    };
  },

  data() {
    return {
      stepForgot: 1,
      isLoading: false,
      emailToken: '',
      newPassword: '',
      emailForgot: '',
    };
  },

  methods: {
    sendRecoveryToEmail() {
      this.isLoading = true;
      axios
        .post('https://api.asemego.com.br/api/forgot', {
          email: this.emailForgot,
        })
        .then(() => {
          localStorage.setItem('emailForgot', this.emailForgot);
          this.stepForgot = 2;
        })
        .catch(() => {
          this.$refs.wrongEmail.showModalWithMessage();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    sendNewPassword() {
      this.loading = true;
      const newPassToEmail = localStorage.getItem('emailForgot');
      axios.post('https://api.asemego.com.br/api/reset', {
        email: newPassToEmail,
        token: this.emailToken,
        password: this.newPassword,
      }).then(() => {
        this.$emit('backStep');
        localStorage.removeItem('emailForgot');
      });
    },
  },
};
</script>
