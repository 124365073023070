<!-- eslint-disable vue/multi-word-component-names -->
<!-- eslint-disable max-len -->
<template>
<footer class="footer">
    <div class="upside py-4 d-flex flex-row container">
        <div class="left-side ms-3 d-flex flex-column justify-content-center"><img class="mb-3" src="../../assets/wp/logo.png" style="width: 200px" /><span class="text-start">Somos a Associação dos Servidores da Educação dos Municípios e do Estado de Goiás, formada por profissionais conhecedores da realidade e, acima de tudo, preocupados em oferecer aos servidores públicos um espaço inovador para atender às demandas individuais e coletivas da categoria.</span></div>
        <div class="middle-side mx-3 d-flex flex-column justify-content-center">
          <span class="text-center mb-2 fw-bold title-asemego-mb">Associacao dos Servidores da Educação dos Municipios e do Estado de Goiás</span>
            <div class="d-flex justify-content-center flex-row mb-2">
                <div class="d-flex flex-column">
                    <div class="text-start"><span class="text-start">CNPJ: 44.303.029/0001-18</span></div>
                    <div class="text-start"><span class="text-start">Rua 88, 547 Qd. F-35 Lt. 59</span></div>
                    <div class="text-start"><span class="text-start">Setor Sul, Goiânia-GO</span></div>
                </div>
            </div>
            <div class="d-flex flex-row justify-content-center">
                <div class="d-flex flex-column">
                    <div class="d-flex">
                      <a class="text-start icon-hover me-4 d-flex" href="https://www.instagram.com/asemego.oficial/" target="_blank">
                        <i class="bi bi-instagram me-2"></i><span>Instagram</span></a><a class="text-start icon-hover d-flex" href="https://api.whatsapp.com/send/?phone=556236246665&amp;text&amp;type=phone_number&amp;app_absent=0" target="_blank">
                          <i class="bi bi-whatsapp me-2"></i><span>Whatsapp</span>
                        </a>
                      </div>
                    <div class="d-flex">
                      <a class="text-start icon-hover me-4 d-flex" href="https://www.facebook.com/people/Asemego-Goi%C3%A1s/100071729289200/" target="_blank"><i class="bi bi-facebook me-2"></i><span>Facebook</span></a><a class="text-start icon-hover d-flex" href="https://www.linkedin.com/in/asemego-goi%C3%A1s-a8649821a/?originalSubdomain=br" target="_blank">
                        <i class="bi bi-linkedin me-2"></i>
                        <span>Linkedin</span>
                      </a>
                    </div>
                </div>
            </div>
    </div>
    <div class="right-side me-3 d-flex flex-column justify-content-center">
      <iframe class="w-100" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15286.734604284242!2d-49.2478679!3d-16.6927066!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x935ef15103c4b0a1%3A0x317fe1ab0a8f97cc!2sASEMEGO!5e0!3m2!1spt-BR!2sbr!4v1681796775308!5m2!1spt-BR!2sbr" width="400" height="250" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
      </iframe>
    </div>
    </div>
    <div class="d-flex flex-row bottomside w-100 justify-content-center"><span class="my-2 fw-bold registered-mb">ASEMEGO © {{ getAnoAtual() }} - Todos os direitos reservados</span></div>
</footer>
</template>

<script>

export default ({
  name: 'FooterComponent',
  components: {},
  data() {
    return {};
  },
  mounted() {
    this.getAnoAtual();
  },
  methods: {
    getAnoAtual() {
      const dataAtual = new Date(); // Cria um objeto Date com a data e hora atuais
      return dataAtual.getFullYear(); // Retorna o ano atual
    },
  },
});
</script>

<style lang="stylus">
footer {
  position: relative !important;
  background-color: #f2f2f1;
}

footer .upside .left-side {
  width: 33.33%;
}

footer .upside .middle-side {
  width: 33.33%;
}

footer .upside .right-side {
  width: 33.33%;
}
footer .bottomside {
  background-color: var(--primaria);
  color: #fff;
}
footer .icon-hover:hover {
  color: var(--secundaria);
}
footer a {
  text-decoration: none;
  font-weight: bold;
  color: var(--primaria);
}
footer iframe {
  border: 2px solid var(--primaria);
}
@media screen and (max-width: 768px) {
  .registered-mb {
    font-size: 13px;
}
  .upside {
    display: flex;
    flex-direction: column !important;
}
  .upside .left-side {
    width: 100% !important;
    margin-bottom: 2rem;
    padding-right: 1rem;
}
  .upside .middle-side {
    width: 100% !important;
    margin-bottom: 3rem;
    padding-right: 1rem;
    margin-left: 0 !important;
    margin-right: 0 !important;
}
  .upside .middle-side span {
    text-align: start !important;
}
  .upside .middle-side .title-asemego-mb {
    text-align: center !important;
}
  .upside .right-side {
    width: 100% !important;
    margin-right: 0 !important;
    display: flex;
    flex-direction: row !important;
    justify-content: center !important;
	}
}
 @media screen and (max-width: 440px) {
  iframe {
    width: 300px !important;
	}
}

</style>
