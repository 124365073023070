<!-- eslint-disable max-len -->
<!-- eslint-disable no-tabs -->

<template>
  <div class="partners-view mt-5 container">
    <h1 class="text-start">Parceiros:</h1>
    <div class="action-buttons d-flex flex-row justify-content-start">
      <button class="btn btn-primary mt-2" @click="openNewPartnelModal">
        <i class="bi bi-person-fill-add me-2"></i>Adicionar Parceiro
      </button>
    </div>
    <table-component
      :columns="columns"
      class="text-start mt-4"
      v-if="partners.length > 0"
    >
      <template v-slot:default>
        <tr v-for="(partner, index) in partners" :key="index">
          <td>{{ partner.id }}</td>
          <td>{{ partner.nome }}</td>
          <td>
            <span v-if="partner.tag == 'others'">Outros</span>
            <span v-else-if="partner.tag == 'health'">Saúde e Bem-estar</span>
            <span v-else-if="partner.tag == 'tech'">Tecnologia</span>
            <span v-else-if="partner.tag == 'industry'">Indústria</span>
            <span v-else-if="partner.tag == 'creativity'">Criatividade</span>
            <span v-else-if="partner.tag == 'education'">Educação</span>
            <span v-else-if="partner.tag == 'business'">Negócios e Finanças</span>
            <span v-else-if="partner.tag == 'entertainment'">Artes e Entretenimento</span>
            <span v-else-if="partner.tag == 'Sustainability'">Sustentabilidade</span>
            <span v-else-if="partner.tag == 'food'">Alimentação e Gastronomia</span>
            <span v-else-if="partner.tag == 'tourism'">Viagens e Turismo</span>
            <span v-else>Categoria nova</span>
          </td>
          <td>
            <span v-if="partner.youtube !== null"
              ><i class="bi bi-check-circle-fill"></i
            ></span>
            <span v-else class="fw-bold">X</span>
          </td>
          <td>
            <span v-if="partner.instagram !== null"
              ><i class="bi bi-check-circle-fill"></i
            ></span>
            <span v-else class="fw-bold">X</span>
          </td>
          <td>
            <span v-if="partner.telefone !== null"
              ><i class="bi bi-check-circle-fill"></i
            ></span>
            <span v-else class="fw-bold">X</span>
          </td>
          <td>
            <span v-if="partner.facebook !== null"
              ><i class="bi bi-check-circle-fill"></i
            ></span>
            <span v-else class="fw-bold">X</span>
          </td>
          <td>
            <span v-if="partner.imagem_1 !== null || partner.imagem_2 !== null"
              ><i class="bi bi-check-circle-fill"></i
            ></span>
            <span v-else class="fw-bold">X</span>
          </td>
          <td>
            <span v-if="partner.logo !== null"
              ><i class="bi bi-check-circle-fill"></i
            ></span>
            <span v-else class="fw-bold">X</span>
          </td>
          <td>
            <span v-if="partner.promocoes !== null"
              ><i class="bi bi-check-circle-fill"></i
            ></span>
            <span v-else class="fw-bold">X</span>
          </td>
          <td>
            <span v-if="partner.descricao !== null"
              ><i class="bi bi-check-circle-fill"></i
            ></span>
            <span v-else class="fw-bold">X</span>
          </td>
          <td>
            <div class="d-flex flex-row justify-content-around">
              <button
                type="button"
                class="btn btn-primary me-2"
                @click="openEditPartnerModal(partner)"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Editar Parceiro"
              >
                <i class="bi bi-pen-fill"></i>
              </button>
              <button
              type="button"
              class="btn btn-success me-2"
              @click="openChangeLogoModal(partner.id, partner.nome)"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Alterar Logo"
              >
              <i class="bi bi-image"></i>
              </button>
              <button
                type="button"
                class="btn btn-secondary me-2"
                @click="openChangePartnerImagesModal(partner.id)"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Alterar Imagens"
                >
                <i class="bi bi-images"></i>
              </button>
              <button
                type="button"
                class="btn btn-danger me-2"
                @click="openDeletePartnerModal(partner.id, partner.nome)"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Deletar Parceiro"
              >
                <i class="bi bi-trash"></i>
              </button>
            </div>
          </td>
        </tr>
      </template>
    </table-component>
    <div
      v-if="partners.length === null || partners.length == 0"
      class="container d-flex flex-row justify-content-center"
    >
      <div class="d-flex flex-column justify-content-center">
        <h2>Não temos parceiros inseridos do sistema!</h2>
        <div class="d-flex justify-content-center">
          <img src="../../../assets/ui/not-found-fatura.png" class="w-50" />
        </div>
      </div>
    </div>
    <InsertNewPartner ref="InsertNewPartner" @insertedPartner="closeNewPartnelModal" />
    <DeletePartnerModal
      ref="DeletePartnerModal"
      :partnerName="partnerNameSelected"
      :partnerId="partnerIdSelected"
      @deletePartner="deletePartner"
    />
    <EditPartnerModal
      ref="EditPartnerModal"
      :partner="partnerObjectSelected"
      @editedPartner="editedPartner"
    />
    <ChangePartnerLogoModal
      ref="ChangePartnerLogoModal"
      :userId="partnerIdSelected"
    />
    <ChangePartnerImagesModal
      ref="ChangePartnerImagesModal"
      :id="partnerIdSelected"
    />
    <Loading v-if="isLoading" />
  </div>
</template>

<!-- eslint-disable no-console -->
<script>
import axios from 'axios';
import TableComponent from '../../../ui/TableComponent.vue';
import InsertNewPartner from '../../../components/modals/InsertNewPartner.vue';
import DeletePartnerModal from '../../../components/modals/DeletePartnerModal.vue';
import EditPartnerModal from '../../../components/modals/EditPartnerModal.vue';
import Loading from '../../../components/molecules/Loading.vue';
import ChangePartnerLogoModal from '../../../components/modals/ChangePartnerLogoModal.vue';
import ChangePartnerImagesModal from '../../../components/modals/ChangePartnerImagesModal.vue';

export default {
  name: 'PartnersView',
  components: {
    InsertNewPartner,
    TableComponent,
    DeletePartnerModal,
    Loading,
    EditPartnerModal,
    ChangePartnerLogoModal,
    ChangePartnerImagesModal,
  },
  data() {
    return {
      isLoading: false,
      partners: [],
      partnerIdSelected: '',
      partnerObjectSelected: Object,
      partnerNameSelected: '',
      columns: [
        { label: 'ID', field: 'id' },
        { label: 'Nome', field: 'name' },
        { label: 'TAG', field: 'email' },
        { label: 'Youtube', field: 'youtube' },
        { label: 'Instagram', field: 'instagram' },
        { label: 'Telefone', field: 'telefone' },
        { label: 'Facebook', field: 'facebook' },
        { label: 'Imagens', field: 'imagens' },
        { label: 'Logo', field: 'logo' },
        { label: 'Promoções', field: 'Promoções' },
        { label: 'Descrição', field: 'descricao' },
        { label: 'Ações', field: 'acoes' },
      ],
    };
  },
  mounted() {
    this.toLoadTablePartner();
  },
  methods: {
    toLoadTablePartner() {
      this.isLoading = true;
      axios
        .get('https://api.asemego.com.br/api/parceiros')
        .then((res) => {
          this.partners = res.data.sort((a, b) => a.id - b.id);
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    openNewPartnelModal() {
      this.$refs.InsertNewPartner.showModalWithForm();
    },
    closeNewPartnelModal() {
      this.$refs.InsertNewPartner.hideModal();
      this.toLoadTablePartner();
    },
    openDeletePartnerModal(partnerId, partnerName) {
      this.partnerIdSelected = partnerId;
      this.partnerNameSelected = partnerName;
      this.$refs.DeletePartnerModal.showModalToDelete();
    },
    deletePartner(partnerId) {
      this.isLoading = true;
      const token = localStorage.getItem('token');
      if (token) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      }
      axios
        .delete(`https://api.asemego.com.br/api/parceiros/${partnerId}`)
        .then(() => {})
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
          this.$refs.DeletePartnerModal.hideModal();
          this.toLoadTablePartner();
        });
    },
    openEditPartnerModal(partner) {
      this.partnerObjectSelected = partner;
      this.$refs.EditPartnerModal.showModalWithEditData(partner);
    },
    editedPartner() {
      this.$refs.EditPartnerModal.hideModal();
      this.toLoadTablePartner();
    },
    openChangeLogoModal(id, name) {
      this.partnerIdSelected = id;
      this.partnerNameSelected = name;
      this.$refs.ChangePartnerLogoModal.showModalChangePartnerLogo(id, name);
    },
    openChangePartnerImagesModal(id) {
      this.$refs.ChangePartnerImagesModal.showModalChangePartnerImage(id);
    },
  },
};
</script>
<!-- eslint-disable no-tabs -->
<style scoped>
.partners-view {
  margin-bottom: 1rem;
}

table td,
table th {
  border-width: 1px;
  border-color: #333342;
  border-style: solid;
  padding: 5px;
}

tr:hover {
  background-color: red;
}
</style>
