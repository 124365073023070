<!-- eslint-disable no-tabs -->
<!-- eslint-disable max-len -->
<template lang="pug">
.login-view
	.d-flex.flex-row.justify-content-center
		.row.justify-content-center
			.card.border-0.shadow-lg.my-5.p-0
				.card-body.p-0
					.d-flex.flex-row.justify-content-center
						.content-card.p-0
							.d-flex.flex-row.w-100.bg-asemego.py-2.justify-content-center.cabecalho
								img(src="@/assets/wp/logob.png")
							.form-login.px-3.py-3(v-if="loginStep === 1" @submit.prevent="onSubmit")
								login-form
								button.btn.btn-outline-secondary.w-100.mt-2(@click='loginStep = 3') Esqueci minha senha
								button.btn.btn-outline-info.w-100.mt-2(@click="loginStep++") Criar conta
							.form-create-account.py-3.px-3(v-if="loginStep === 2")
								h4.text-center.mb-3.fw-bold Criar nova conta
								SignUpForm(@backStep="backStepAccount" @voltarStep="backStepDontBill" @accountCreated="createdAccount")
								button.btn.btn-outline-danger.w-100(@click="loginStep = 1") Voltar
							.form.form-password-recovery.py-3.px-3(v-if="loginStep === 3")
								h4.text-center.mb-3 Recuperar senha
								RecoveryForm(@backStep="backStep")
								.d-flex.flex-column.justify-content-center.align-items-center
									button.btn.btn-outline-danger.w-100(@click="loginStep = 1") Voltar
<RecoveryPassword ref="recoveryPasswordSuccess"/>
<HaveAccount ref="HaveAccount" />
<DontHaveBill ref="DontHaveBill"/>
<AccountCreated ref="AccountCreated"/>
</template>

<!-- eslint-disable no-mixed-spaces-and-tabs -->
<script>
import Modal from '@/components/organisms/Modal.vue';
import LoginForm from '../components/forms/LoginForm.vue';
import SignUpForm from '../components/forms/SignUpForm.vue';
import RecoveryForm from '../components/forms/RecoveryForm.vue';
import RecoveryPassword from '../components/modals/RecoveryPassword.vue';
import HaveAccount from '../components/modals/HaveAccount.vue';
import DontHaveBill from '../components/modals/DontHaveBill.vue';
import AccountCreated from '../components/modals/AccountCreated.vue';

export default {

  data() {
    return {
      loginStep: 1,
      codeEs: '',
      emailToVerify: '',
      showModal: false,
      token: '',
    };
  },
  components: {
    Modal,
    LoginForm,
    SignUpForm,
    RecoveryForm,
    RecoveryPassword,
    HaveAccount,
    DontHaveBill,
    AccountCreated,
  },
  methods: {
    backStep() {
      this.loginStep = 1;
	  this.$refs.recoveryPasswordSuccess.showModalWithMessage();
    },
    backStepAccount() {
      this.loginStep = 1;
      this.$refs.HaveAccount.showModalWithMessage();
    },
    backStepDontBill() {
      this.loginStep = 1;
      this.$refs.DontHaveBill.showModalWithMessage();
    },
    createdAccount() {
      this.loginStep = 1;
      this.$refs.AccountCreated.showModalWithMessage();
    },
  },
};
</script>

<!-- eslint-disable no-tabs -->
<style lang="stylus" scoped>
.login-view
	min-height: 55vh
	button
		width: 200px

.bg-asemego
	background-color: #998F7B

.content-card
	width: 650px

.cabecalho
	border-top-left-radius: 5px
	border-top-right-radius: 5px

.address-input
	width: 70%
.number-input
	width 20%

.details-input
	width: 70%
.cep-input
	width: 20%

.state-input
	width: 15%
.city-input
	width: 50%
.bairro-input
	width: 30%

@media screen and (max-width: 768px)
	button
		width: 100% !important
	.content-card
		width: 400px
</style>
