<!-- eslint-disable max-len -->
<!-- eslint-disable no-tabs -->
<template>
<div>
	<!-- Modal -->
	<div class="modal" tabindex="-1" role="dialog" :class="{ 'show': showModal }">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title"><i class="bi bi-person-bounding-box me-2"></i>Recortar Imagem</h5>
					<button type="button" class="btn btn-danger" @click="hideModal">
						<span aria-hidden="true">X</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="mb-4 d-flex justify-content-center">
						<vue-cropper
              class="cropper-component"
              v-if="imageSource !== ''"
              ref="cropper"
              :src="imageSource"
              :aspectRatio="1/1"
              :viewMode="2"
              :initialAspectRatio="1/1"
            />
					</div>
          <div class="modal-actions d-flex justify-content-center">
            <button class="btn btn-success me-2" @click="cropImage"><i class="bi bi-person-bounding-box me-2"></i>Recortar</button>
            <button class="btn btn-danger" @click="hideModal">Cancelar</button>
          </div>
				</div>
			</div>
		</div>
	</div>
  <Loading v-if="isLoading"/>
</div>
</template>

<script>
import axios from 'axios';
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import Loading from '../molecules/Loading.vue';

export default {
  data() {
    return {
      isLoading: false,
      showModal: false,
      imageSource: '',
      croppedImageBlobFile: null,
    };
  },
  emits: ['cropped'],
  components: { VueCropper, Loading },
  methods: {
    showModalToCrop(image) {
      this.showModal = true;
      this.imageSource = image;
    },
    hideModal() {
      this.showModal = false;
      this.imageSource = '';
    },
    cropImage() {
      this.isLoading = true
      const croppedCanvas = this.$refs.cropper.getCroppedCanvas();

      // Obtém o Blob da imagem recortada
      croppedCanvas.toBlob((blob) => {
        this.croppedImageBlobFile = blob;

        const formData = new FormData();
        formData.append('image', this.croppedImageBlobFile);

        const token = localStorage.getItem('token');
        if (token) {
          axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        }

        const userid = localStorage.getItem('id');

        axios.post(`https://api.asemego.com.br/api/usuarios/${userid}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(() => {
            this.$emit('cropped');

          })
          .catch(() => {
          })
          .finally(() => {
            this.isLoading = false;
          });
        });
    },
  },
};
</script>
<!-- eslint-disable no-tabs -->
<style scoped>
.modal {
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal.show {
  display: block;
}

.modal-dialog {
  margin-top: 10%;
}

.modal-content {
  background-color: #fff;
}

.modal-header {
  background-color: #333342;
  color: #FFF;
  font-weight: bold;
}

.modal-body {
  padding: 0 !important;
}

.img-asemego {
  width: 240px;
}

.cropper-component {
  max-width: 400px;
  border: 5px solid #333342;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .cropper-component {
    width: 400px !important;
  }
}

@media screen and (max-width: 576px) {
  .cropper-component {
    width: 200px !important;
  }
}
</style>
