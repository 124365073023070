/* eslint-disable import/prefer-default-export */
import { defineStore } from 'pinia';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    logged: false,
    isAdmin: false,
    isUser: false,
  }),
  actions: {
    setLogged(value) {
      this.logged = value;
    },
    setAdmin(value) {
      this.isAdmin = value;
    },
    setUser(value) {
      this.isUser = value;
    },
  },
});
