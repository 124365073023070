<!-- eslint-disable max-len -->
<!-- eslint-disable no-tabs -->
<template>
    <div>
        <!-- Modal -->
        <div class="modal" tabindex="-1" role="dialog" :class="{ 'show': showModal }">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" style="font-family: fantasy;"><i class="bi bi-person me-2"></i>INSERIR AGENDAMENTO</h5>
                        <button type="button" class="btn btn-danger" @click="hideModal">
                            <span aria-hidden="true">X</span>
                        </button>
                    </div>
                    <div class="modal-body">
                      <form @submit.prevent="insertSchedule" class="d-flex flex-column justify-content-center">
                        <div class="mb-3">
                          <label for="nome" class="form-label d-flex fw-bold">Nome:</label>
                          <input type="text" v-model="formData.nome" class="form-control" required>
                        </div>
                        <div class="mb-3">
                          <label for="tag" class="form-label d-flex fw-bold">Tag:</label>
                          <select v-model="formData.type" class="form-select">
                            <option value="others" selected>Outros (others)</option>
                            <option value="health">Saúde e Bem-Estar (health)</option>
                            <option value="room">Sala (room)</option>
                            <option value="place">Espaço (place)</option>
                          </select>
                        </div>
                        <div class="mb-3">
                          <label for="telefone" class="form-label d-flex fw-bold">Telefone:</label>
                          <input type="text" v-model="formData.telefone" class="form-control">
                        </div>
                        <div class="mb-3">
                          <label for="telefone" class="form-label d-flex fw-bold">Whatsapp:</label>
                          <input type="text" v-model="formData.whatsapp" class="form-control">
                        </div>
                        <div class="mb-3">
                          <label for="logo" class="form-label d-flex fw-bold">Imagem:</label>
                          <input type="file" accept=".png, .jpg, .jpeg" @change="selectImage1" class="form-control">
                        </div>
                        <div class="mb-3">
                          <label for="logo" class="form-label d-flex fw-bold">Imagem 2:</label>
                          <input type="file" accept=".png, .jpg, .jpeg" @change="selectImage2" class="form-control">
                        </div>
                        <div class="mb-3">
                          <label for="descricao" class="form-label d-flex fw-bold">Descrição:</label>
                          <textarea v-model="formData.descricao" class="form-control" rows="10"></textarea>
                        </div>
                        <button type="submit" class="btn btn-primary"><i class="bi bi-plus me-2"></i>Inserir parceiro</button>
                      </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Loading v-if="isLoading"/>
</template>

<!-- eslint-disable no-console -->
<script>
import axios from 'axios';
import Loading from '../molecules/Loading.vue';

export default {
  data() {
    return {
      isLoading: false,
      showModal: false,
      formData: {
        nome: '',
        type: '',
        telefone: '',
        whatsapp: '',
        descricao: '',
        imagem_1: '',
        imagem_2: '',
        imagem_1_Selected: null,
        imagem_2_Selected: null,
      },
    };
  },
  emits: ['created'],
  components: { Loading },
  methods: {
    hideModal() {
      this.showModal = false;
    },
    showModalWithForm() {
      this.showModal = true;
    },
    selectImage1(img1) {
      // eslint-disable-next-line prefer-destructuring
      this.formData.imagem_1_Selected = img1.target.files[0];
    },
    selectImage2(img2) {
      // eslint-disable-next-line prefer-destructuring
      this.formData.imagem_2_Selected = img2.target.files[0];
    },
    insertSchedule() {
      this.isLoading = true;
      const formData = new FormData();

      const dataToAppend = {
        nome: this.formData.nome,
        tipo: this.formData.type,
        telefone: this.formData.telefone,
        whatsapp: this.formData.whatsapp,
        descricao: this.formData.descricao,
        imagem: this.formData.imagem_1_Selected,
        imagem_2: this.formData.imagem_2_Selected,
      };

      const token = localStorage.getItem('token');
      if (token) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const key in dataToAppend) {
        if (dataToAppend[key] !== null && dataToAppend[key] !== '') {
          formData.append(key, dataToAppend[key]);
        }
      }

      axios.post('https://api.asemego.com.br/api/agendamentos', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(() => {
          // Handle success
          this.formData = {
            nome: '',
            tipo: '',
            telefone: '',
            whatsapp: '',
            descricao: '',
            imagem_1: '',
            imagem_2: '',
            imagem_1_Selected: null,
            imagem_2_Selected: null,
          };

          // Limpe os campos de seleção de arquivo
          this.$refs.image1Input.value = null;
          this.$refs.image2Input.value = null;
        })
        .catch(() => {
          // Handle error
        })
        .finally(() => {
          this.$emit('created');
          this.isLoading = false;
        });
    },
  },
};
</script>
<!-- eslint-disable no-tabs -->
<style scoped>
.modal {
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal.show {
  display: block;
}

.modal-dialog {
  margin-top: 10%;
}

.modal-content {
  background-color: #fff;
}

.modal-header {
    background-color: #333342;
    color: #FFF;
    font-weight: bold;
}

.img-asemego {
    width: 240px;
}

.btn-primary {
  background-color: #333342 !important;
  border-color: #333342 !important;
}
</style>
