<template>
<div class="plans-view mt-5">
  <h1 class="container text-start">Planos:</h1>
  <table-component :columns="columns" class="text-start">
    <template v-slot:default>
      <tr v-for="(plan, index) in plans" :key="index">
        <td>{{ plan.id }}</td>
        <td>{{ plan.name }}</td>
        <td>
          <span v-if="plan.status == 'active'" class="fw-bold text-success">Ativo</span>
          <span v-else class="fw-bold text-danger">Inativo</span>
        </td>
      </tr>
    </template>
  </table-component>
  <Loading v-if="!isLoading"/>
</div>
</template>

<script>
import axios from 'axios';
import Loading from '@/components/molecules/Loading.vue';
import TableComponent from '../../../ui/TableComponent.vue';

export default {
  data() {
    return {
      plans: [],
      isLoading: false,
      columns: [
        { label: 'ID', field: 'id' },
        { label: 'Nome', field: 'name' },
        { label: 'Status', field: 'status' },
      ],
    };
  },

  components: { Loading, TableComponent },

  beforeCreate() {
    this.isLoading = false;
    const token = localStorage.getItem('token');
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
    axios
      .get('https://api.asemego.com.br/api/planos', {})
      .then((res) => {
        // eslint-disable-next-line prefer-destructuring
        this.plans = res.data.plans;
      })
      .catch(() => {
      })
      .finally(() => {
        this.isLoading = true;
      });
  },
};
</script>

<style scoped>

table td, table th {
  border-width: 1px;
  border-color: #333342;
  border-style: solid;
  padding: 5px;
}

tr:hover {
	background-color: lightcoral;
}
</style>
