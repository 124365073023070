<!-- eslint-disable max-len -->
<!-- eslint-disable no-tabs -->
<template>
<div>
	<!-- Modal -->
	<div class="modal" tabindex="-1" role="dialog" :class="{ 'show': showModal }">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">{{ errorMessage }}!</h5>
					<button type="button" class="btn btn-danger" @click="hideModal">
						<span aria-hidden="true">X</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="mb-4">
						<img class="img-asemego" src="../../assets/wp/logo.png">
					</div>
					<p class="text-center">Verifique os dados, se estão digitados corretamente e tente novamente.</p>
					<p class="text-center">Se persistir, contate nosso suporte.</p>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
      errorMessage: '',
    };
  },
  methods: {
    showModalWithMessage(message) {
      this.errorMessage = message;
      this.showModal = true;
    },
    hideModal() {
      this.showModal = false;
    },
  },
};
</script>
<!-- eslint-disable no-tabs -->
  <style scoped>
  .modal {
    display: none;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .modal.show {
    display: block;
  }

  .modal-dialog {
    margin-top: 10%;
  }

  .modal-content {
    background-color: #fff;
  }

	.modal-header {
		background-color: #333342;
		color: #FFF;
		font-weight: bold;
	}

	.img-asemego {
		width: 240px;
	}
  </style>
