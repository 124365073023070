<template>
  <div class="convenio-view my-5">
    <h2 class="my-4 fw-bold">Convênios exclusivos!</h2>
    <div class="container">
      <div class="row">
        <div class="container">
          <div class="col-12 mb-4 col-md-3 ps-1 ps-md-5">
            <div class="input-group mb-3">
              <label class="input-group-text" for="inputGroupSelect01"><i class="bi bi-funnel"></i></label>
              <select v-model="formData.tag" class="form-select custom-select">
              <option value="others" selected>Sem filtro</option>
              <option value="tech">Tecnologia</option>
              <option value="health">Saúde e Bem-Estar</option>
              <option value="business">Negócios e Finanças</option>
              <option value="food">Alimentação e Gastronomia</option>
              <option value="tourism">Viagens e Turismo</option>
            </select>
          </div>
        </div>
        </div>
        <div class="img-partner col-6 col-md-4" v-for="partner in paginatedPartners" :key="partner.id">
          <router-link :to="'/partners/' + partner.id">
            <div class="card-convenio">
              <img :src='convertLinkLogo(partner.id)'>
            </div>
          </router-link>
        </div>
        <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-center">
              <li class="page-item" :class="{ disabled: currentPage === 1 }">
                <button class="page-link" @click="changePage(currentPage - 1)" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                </button>
              </li>
              <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
                <button class="page-link" @click="changePage(page)">{{ page }}</button>
              </li>
              <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                <button class="page-link" @click="changePage(currentPage + 1)" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                </button>
              </li>
            </ul>
          </nav>
      </div>
    </div>
    <Loading v-if="isLoading"/>
  </div>
  </template>

<!-- eslint-disable max-len -->
<!-- eslint-disable global-require -->
<script>
import axios from 'axios';
import Loading from '../components/molecules/Loading.vue';

export default {
  data() {
    return {
      partners: [],
      currentPage: 1,
      itemsPerPage: 20,
      totalPages: 0,
      isLoading: false,
      formData: {
        tag: 'others',
      },
    };
  },
  components: { Loading },
  mounted() {
  this.isLoading = true;
  axios.get('https://api.asemego.com.br/api/parceiros')
    .then((res) => {
      this.partners = res.data;
      this.totalPages = Math.ceil(this.filteredPartners.length / this.itemsPerPage);
    })
    .catch(() => {
    }).finally(() => {
      this.isLoading = false;
    });
},
  methods: {
    convertLinkLogo(id) {
      const link = `https://api.asemego.com.br/api/parceiros/${id}/logo`;
      return link;
    },
    changePage(page) {
      this.currentPage = page;
    },
  },
  watch: {
    formData: {
      deep: true,
      handler() {
        this.currentPage = 1;
        this.totalPages = Math.ceil(this.filteredPartners.length / this.itemsPerPage);
      },
    },
  },
  computed: {
    filteredPartners() {
      if (this.formData.tag === 'others') {
        return this.partners;
      }
      return this.partners.filter(partner => partner.tag === this.formData.tag);
    },
    paginatedPartners() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredPartners.slice(start, end);
    },
  },
};
</script>

<!-- eslint-disable no-tabs -->
<style scoped>
img {
	 height: 200px;
	 width: 200px;
	 border-radius: 6px;
	 z-index: 1;
	 -webkit-box-shadow: 0px 1px 11px 5px rgba(0,0,0,0.36);
	 -moz-box-shadow: 0px 1px 11px 5px rgba(0,0,0,0.36);
	 box-shadow: 0px 1px 11px 5px rgba(0,0,0,0.36);
	 opacity: 0.5 !important;
	 transition: opacity 0.3s ease, box-shadow 0.3s ease;
}
 img:hover {
	 opacity: 1 !important;
}
 .img-partner {
	 margin-bottom: 1rem !important;
}
 .convenio-view {
	 height: 100% !important;
}

.pagination {
    margin-top: 1rem;
  }

  .page-item.disabled .page-link {
    cursor: not-allowed;
    background-color: #ACA28E;
    border-color: #ACA28E;
    color: white;
    opacity: 0.5;
  }
  
  .page-item > .page-link {
    color: white;
    background-color: #ACA28E;
    border-color: #ACA28E;

  }

  .page-item > .page-link:hover {
    color: #333342;
    background-color: #ACA28E;
    border-color: #333342;
  }

  .page-item.active > .page-link {
    background-color: #333342;
    border-color: #ACA28E;
    color: white;
  }

  .input-group-text {
    background-color: #333342;
    border-color: #333342;
    color: white;
  }

  .custom-select {
  background-color: #f8f9fa; /* Cor de fundo */
  color: #343a40; /* Cor do texto */
  border: 1px solid #ced4da; /* Borda */
}

.custom-select:focus {
  background-color: #e9ecef; /* Cor de fundo ao focar */
  color: #495057; /* Cor do texto ao focar */
  border-color: #80bdff; /* Cor da borda ao focar */
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-select option {
  background-color: #ffffff; /* Cor de fundo das opções */
  color: #343a40; /* Cor do texto das opções */
}
 @media screen and (max-width: 768px) {
	 .convenio-view {
		 height: 100% !important;
	}
	 .convenio-view .img-partner {
		 margin-bottom: 1rem !important;
	}
	 .convenio-view img {
		 height: 130px;
		 width: 130px;
		 opacity: 1 !important;
	}
}
 @media screen and (min-width: 991px) {
	 .convenio-view {
		 height: 100% !important;
	}
	 .convenio-view .img-partner {
		 margin-bottom: 1rem !important;
	}
	 .convenio-view img {
		 height: 300px;
		 width: 300px;
	}
}
</style>
