<!-- eslint-disable max-len -->
<template>
<div class="accordion" id="accordionExample">
  <div class="accordion-item">
    <span class="accordion-header title-accordion" id="first">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#primeiro" aria-expanded="true" aria-controls="primeiro">
        Sobre a ASEMEGO
      </button>
    </span>
    <div id="primeiro" class="accordion-collapse collapse show" aria-labelledby="first" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        Somos uma Associação dos Servidores da Educação do Município e do Estado de Goiás, formada por profissionais conhecedores da realidade e acima de tudo preocupados em oferecer ao servidor(a) um espaço inovador que venha atender demandas individuais e coletivas da categoria.
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <span class="accordion-header title-accordion" id="second">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#segundo" aria-expanded="false" aria-controls="segundo">
        Missão
      </button>
    </span>
    <div id="segundo" class="accordion-collapse collapse" aria-labelledby="second" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        Proporcionar apoio e parceria a todos os servidores associados da EDUCAÇÃO do município de Goiânia e estado de Goiás. Ser reconhecidamente uma parceira decisiva, trazendo confiança aos seus associados, proporcionando inúmeras vantagens, fortalecendo e estreitando os vínculos dos servidores da Educação.      </div>
    </div>
  </div>
  <div class="accordion-item">
    <span class="accordion-header title-accordion" id="third">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#terceiro" aria-expanded="false" aria-controls="terceiro">
        Visão
      </button>
    </span>
    <div id="terceiro" class="accordion-collapse collapse" aria-labelledby="third" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        A ASEMEGO tem como visão acolher os servidores da educação do município de Goiânia e do estado de Goiás, professores, profissionais administrativos e servidores aposentados, constituindo-se em uma rede de apoio que oferece modernidade, comodidade e um espaço confiável para apoiar os associados.
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <span class="accordion-header title-accordion" id="fourth">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#quarto" aria-expanded="false" aria-controls="quarto">
        Igualdade
    </button>
    </span>
    <div id="quarto" class="accordion-collapse collapse" aria-labelledby="fourth" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        Todos associados terão o mesmo tratamento frente às necessidades de forma igualitária.
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <span class="accordion-header title-accordion" id="fifth">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#quinto" aria-expanded="false" aria-controls="quinto">
            União
        </button>
    </span>
    <div id="quinto" class="accordion-collapse collapse" aria-labelledby="fifth" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        Unir forças numa coletividade de ajuda mútua, pois acreditamos que a educação é formada por todos nós, sem distinção de função e cargo.
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <span class="accordion-header title-accordion" id="sixth">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sexto" aria-expanded="false" aria-controls="sexto">
            Parceria
        </button>
    </span>
    <div id="sexto" class="accordion-collapse collapse" aria-labelledby="sixth" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        Criar uma rede de benefícios e um clube de vantagens aos seus associados, buscando sempre o bem-estar dos servidores por meio de uma rede de colaboradores.
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <span class="accordion-header title-accordion" id="seventh">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#setimo" aria-expanded="false" aria-controls="setimo">
        Empatia
      </button>
    </span>
    <div id="setimo" class="accordion-collapse collapse" aria-labelledby="seventh" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        Promover a compreensão e sensibilidade às realidades individuais de cada associado, garantindo uma abordagem acolhedora e adaptativa às suas necessidades e expectativas.      </div>
    </div>
  </div>
  <div class="accordion-item">
    <span class="accordion-header title-accordion" id="oct">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#oitavo" aria-expanded="false" aria-controls="oitavo">
        Respeito
      </button>
    </span>
    <div id="oitavo" class="accordion-collapse collapse" aria-labelledby="oct" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        Assegurar um ambiente de respeito mútuo, valorizando a diversidade de experiências e opiniões entre os associados, e mantendo sempre uma conduta ética e de integridade nas interações.
      </div>
</div>
  </div>
</div>
</template>

<style>
.accordion {
  --bs-accordion-btn-active-icon: none !important;
}
.accordion-button {
  position: relative;
  display: flex;
  align-items: start;
  width: 100%;
  text-align: start !important;
  font-size: 1rem;
  color: #333342 !important;
  text-align: left;
  background-color: #f2f2f2 !important;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}

.accordion-body {
    text-align: start;
}

.accordion-button:not(.collapsed) {
    color: #988E7A !important;
    background-color: #333342 !important;
}
.accordion-button:focus {
    z-index: 3;
    border-color: #333342 !important;
    outline: 0;
    box-shadow: #333342 !important;
}

.accordion-button:hover {
    z-index: 2;
}

.title-accordion > button {
    font-weight: bold !important;
}
/* --primaria: #333342
  --secundaria: #988E7A */
</style>
