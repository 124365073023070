<!-- eslint-disable max-len -->
<!-- eslint-disable no-tabs -->
<template>
    <div>
        <!-- Modal -->
        <div class="modal" tabindex="-1" role="dialog" :class="{ 'show': showModal }">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" style="font-family: fantasy;"><i class="bi bi-person-fill-gear me-2"></i>EDITAR PARCEIRO</h5>
                        <button type="button" class="btn btn-danger" @click="hideModal">
                            <span aria-hidden="true">X</span>
                        </button>
                    </div>
                    <div class="modal-body">
                      <form @submit.prevent="insertPartner" class="d-flex flex-column justify-content-center">
                        <div class="mb-3">
                          <label for="nome" class="form-label d-flex fw-bold">Nome:</label>
                          <input type="text" v-model="partnerToEdit.nome" class="form-control" required>
                        </div>
                        <div class="mb-3">
                          <label for="tag" class="form-label d-flex fw-bold">Tag:</label>
                          <select v-model="partnerToEdit.tag" class="form-select">
                            <option value="others" selected>Outros</option>
                            <option value="tech">Tecnologia</option>
                            <option value="industry">Indústria</option>
                            <option value="creativity">Criatividade</option>
                            <option value="education">Educação</option>
                            <option value="health">Saúde e Bem-Estar</option>
                            <option value="business">Negócios e Finanças</option>
                            <option value="entertainment">Artes e Entretenimento</option>
                            <option value="Sustainability">Sustentabilidade</option>
                            <option value="food">Alimentação e Gastronomia</option>
                            <option value="tourism">Viagens e Turismo</option>
                          </select>
                        </div>
                        <div class="mb-3">
                          <label for="youtube" class="form-label d-flex fw-bold">Youtube:</label>
                          <input type="text" v-model="partnerToEdit.youtube" class="form-control">
                        </div>
                        <div class="mb-3">
                          <label for="instagram" class="form-label d-flex fw-bold">Instagram:</label>
                          <input type="text" v-model="partnerToEdit.instagram" class="form-control">
                        </div>
                        <div class="mb-3">
                          <label for="instagram" class="form-label d-flex fw-bold">Instagram 2:</label>
                          <input type="text" v-model="partnerToEdit.instagram_2" class="form-control">
                        </div>
                        <div class="mb-3">
                          <label for="telefone" class="form-label d-flex fw-bold">Telefone: (Ex: tel:+556230884876)</label>
                          <input type="text" v-model="partnerToEdit.telefone" class="form-control">
                        </div><div class="mb-3">
                          <label for="telefone" class="form-label d-flex fw-bold">Telefone 2: (Ex: tel:+556230884876)</label>
                          <input type="text" v-model="partnerToEdit.telefone_2" class="form-control">
                        </div>
                        <div class="mb-3">
                          <label for="facebook" class="form-label d-flex fw-bold">Facebook:</label>
                          <input type="text" v-model="partnerToEdit.facebook" class="form-control">
                        </div>
                        <div class="mb-3">
                          <label for="facebook" class="form-label d-flex fw-bold">Whatsapp: (Ex: http://wa.me/55629XXXXXXXX)</label>
                          <input type="text" v-model="partnerToEdit.whatsapp" class="form-control">
                        </div>
                        <div class="mb-3">
                          <label for="facebook" class="form-label d-flex fw-bold">Whatsapp 2: (Ex: http://wa.me/55629XXXXXXXX)</label>
                          <input type="text" v-model="partnerToEdit.whatsapp_2" class="form-control">
                        </div>
                        <div class="mb-3">
                          <label for="website" class="form-label d-flex fw-bold">Website</label>
                          <input type="text" v-model="partnerToEdit.website" class="form-control">
                        </div>
                        <div class="mb-3">
                          <label for="promocoes" class="form-label d-flex fw-bold">Promoções:</label>
                          <input v-model="partnerToEdit.promocoes" class="form-control">
                        </div>
                        <div class="mb-3">
                          <label for="descricao" class="form-label d-flex fw-bold">Descrição:</label>
                          <input v-model="partnerToEdit.descricao" class="form-control">
                        </div>
                        <button type="submit" class="btn btn-primary" @click="editPartnerForm"><i class="bi bi-person-fill-gear me-2"></i>Editar parceiro</button>
                      </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Loading v-if="isLoading" />
</template>

<!-- eslint-disable no-console -->
<script>
import axios from 'axios';
import Loading from '../molecules/Loading.vue';

export default {
  data() {
    return {
      isLoading: false,
      showModal: false,
      partnerToEdit: {},
    };
  },
  emits: ['editedPartner'],
  components: { Loading },
  props: {
    partner: {},
  },
  methods: {
    hideModal() {
      this.showModal = false;
      this.partnerToEdit = {};
    },
    showModalWithEditData(partner) {
      this.partnerToEdit = { ...partner };
      this.showModal = true;
    },
    editPartnerForm() {
      this.isLoading = true;
      const formData = new FormData();

      const addFormDataIfNotEmpty = (key, value) => {
        if (value !== null && value.trim() !== '') {
          formData.append(key, value);
        }
      };

      addFormDataIfNotEmpty('nome', this.partnerToEdit.nome);
      addFormDataIfNotEmpty('tag', this.partnerToEdit.tag);
      addFormDataIfNotEmpty('facebook', this.partnerToEdit.facebook);
      addFormDataIfNotEmpty('youtube', this.partnerToEdit.youtube);
      addFormDataIfNotEmpty('instagram', this.partnerToEdit.instagram);
      addFormDataIfNotEmpty('instagram_2', this.partnerToEdit.instagram_2);
      addFormDataIfNotEmpty('telefone', this.partnerToEdit.telefone);
      addFormDataIfNotEmpty('telefone_2', this.partnerToEdit.telefone_2);
      addFormDataIfNotEmpty('whatsapp', this.partnerToEdit.whatsapp);
      addFormDataIfNotEmpty('whatsapp_2', this.partnerToEdit.whatsapp_2);
      addFormDataIfNotEmpty('website', this.partnerToEdit.website);
      addFormDataIfNotEmpty('promocoes', this.partnerToEdit.promocoes);
      addFormDataIfNotEmpty('descricao', this.partnerToEdit.descricao);

      const token = localStorage.getItem('token');
      if (token) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      }

      axios.post(`https://api.asemego.com.br/api/parceiros/${this.partner.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        // Lógica para tratamento de sucesso
      })
      .catch(() => {
        // Lógica para tratamento de erro
      })
      .finally(() => {
        this.isLoading = false;
        this.$emit('editedPartner');
      });
    },
  },

};
</script>
<!-- eslint-disable no-tabs -->
<style scoped>
.modal {
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal.show {
  display: block;
}

.modal-dialog {
  margin-top: 10%;
}

.modal-content {
  background-color: #fff;
}

.modal-header {
    background-color: #333342;
    color: #FFF;
    font-weight: bold;
}

.img-asemego {
    width: 240px;
}

.btn-primary {
  background-color: #333342 !important;
  border-color: #333342 !important;
}

</style>
