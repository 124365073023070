<template>
  <div>
    <button @click="sendImage" class="btn btn-primary me-2"><i class="bi bi-person-circle me-1"></i>Alterar foto</button>
  </div>
  <AvatarImageCropp ref="AvatarImageCropp" @cropped="imageCropped"/>
</template>
  
<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import AvatarImageCropp from '../modals/AvatarImageCropp.vue';

export default {
  data() {
    return {
      imageSrc: '',
    };
  },
  emits: ['updateAvatar'],
  components: { VueCropper, AvatarImageCropp },
  methods: {
    sendImage() {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      input.addEventListener('change', this.handleFileChange);
      input.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
        this.imageSrc = e.target.result;
        this.$refs.AvatarImageCropp.showModalToCrop(this.imageSrc);
        };
        reader.readAsDataURL(file);
      }
    },
    imageCropped() {
      this.$refs.AvatarImageCropp.hideModal();
      this.$emit('updateAvatar');
    },
  },
};
</script>
