<!-- eslint-disable max-len -->
<template lang="pug">
.overall-view
  .container.my-3
    h3.text-start Dashboard
    .row.gap-2
    .row
      .col-12.col-xl-3.col-md-6.mb-4
        .card.border-left-success.shadow.h-100.py-2
          .card-body
            .row.no-gutters.align-items-center
              .col.mr-2
                .text-start.font-weight-bold.text-primary.text-uppercase.mb-1
                  | Assinaturas (Ativas)
                .text-start.h5.mb-0.font-weight-bold.text-gray-800 29
              .col-auto
                i.bi.bi-person.fa-2x.text-gray-300
      .col-12.col-xl-3.col-md-6.mb-4
        .card.border-left-success.shadow.h-100.py-2
          .card-body
            .row.no-gutters.align-items-center
              .col.mr-2
                .text-start.font-weight-bold.text-success.text-uppercase.mb-1
                  | Ticket Médio
                .text-start.h5.mb-0.font-weight-bold.text-gray-800 R$45,18
              .col-auto
                i.bi.bi-person.fa-2x.text-gray-300
      .col-12.col-xl-3.col-md-6.mb-4
        .card.border-left-success.shadow.h-100.py-2
          .card-body
            .row.no-gutters.align-items-center
              .col.mr-2
                .text-start.font-weight-bold.text-info.text-uppercase.mb-1
                  | Tasks
                .row.no-gutters.align-items-center
                  .col-auto
                    .text-start.h5.mb-0.mr-3.font-weight-bold.text-gray-800 AGUARDANDO REQUISIÇÃO
                  .col
                    .progress.progress-sm.mr-2
                      .progress-bar.bg-info(role='progressbar' style='width: 50%' aria-valuenow='50' aria-valuemin='0' aria-valuemax='100')
              .col-auto
                i.bi.bi-person.fa-2x.text-gray-300
      .col-12.col-xl-3.col-md-6.mb-4
        .card.border-left-good.shadow.h-100.py-2
          .card-body
            .row.no-gutters.align-items-center
              .col.mr-2
                .text-center.font-weight-bold.text-warning.text-uppercase.mb-1
                  | Cobranças
                .d-flex.flex-row.h5.mb-0.font-weight-bold.text-gray-800.justify-content-between
                  .d-flex.flex-column.justify-content-center
                    span 2
                    span PAGAS
                  .d-flex.flex-column.justify-content-center
                    span 8
                    span VENCIDAS
      .col-12.col-xl-3.col-md-6.mb-4
        .card.border-left-warning.shadow.h-100.py-2
          .card-body
            .row.no-gutters.align-items-center
              .col.mr-2
                .text-start.font-weight-bold.text-success.text-uppercase.mb-1
                  | ALERTAS
                .text-start.h5.mb-0.font-weight-bold.text-gray-800 Sem alertas
              .col-auto
                i.bi.bi-person.fa-2x.text-gray-300
      .col-12.col-xl-3.col-md-6.mb-4
        .card.border-left-good.shadow.h-100.py-2
            .card-body
              .row.no-gutters.align-items-center
                .col.mr-2
                  .text-start.font-weight-bold.text-success.text-uppercase.mb-1
                    | CLIENTES ATIVOS
                  .text-start.h5.mb-0.font-weight-bold.text-gray-800 AGUARDANDO REQUISIÇÃO
                .col-auto
                  i.bi.bi-person.fa-2x.text-gray-300
      .col-12.col-xl-3.col-md-6.mb-4
        .card.border-left-orange.shadow.h-100.py-2
            .card-body
              .row.no-gutters.align-items-center
                .col.mr-2
                  .text-start.font-weight-bold.text-success.text-uppercase.mb-1
                    | CLIENTES INATIVOS
                  .text-start.h5.mb-0.font-weight-bold.text-gray-800 AGUARDANDO REQUISIÇÃO
                .col-auto
                  i.bi.bi-person.fa-2x.text-gray-300
      .col-12.col-xl-3.col-md-6.mb-4
        .card.border-left-red.shadow.h-100.py-2
            .card-body
              .row.no-gutters.align-items-center
                .col.mr-2
                  .text-start.font-weight-bold.text-success.text-uppercase.mb-1
                    | CLIENTES INADIMPLENTES
                  .text-start.h5.mb-0.font-weight-bold.text-gray-800 AGUARDANDO REQUISIÇÃO
                .col-auto
                  i.bi.bi-person.fa-2x.text-gray-300
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<!-- eslint-disable no-tabs -->
<style lang="stylus" scoped>
.border-left-success
  border-left: 0.25rem solid #1cc88a!important;
.border-left-good
  border-left: 0.25rem solid blue !important;
.border-left-danger
  border-left: 0.25rem solid red !important;
.border-left-warning
  border-left: 0.25rem solid orange !important;
</style>
