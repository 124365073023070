<!-- eslint-disable no-tabs -->
<template lang="pug">
.dashboard-view
	.d-flex.flex-row
		.left-navbar.d-flex.flex-column(style="color: #333342;")
			router-link(to="/dashboard/overall").d-flex.flex-column.w-100
				.icon-to-dash.bi.bi-speedometer
				span.fw-bold Dashboard
			.menu-options.text-center.mt-5
				router-link.options(to="/dashboard/users").d-flex.flex-column.w-100
					.bi.bi-person-fill
					span Usuários
				router-link.options(to="/dashboard/plans").d-flex.flex-column.w-100
					.bi.bi-journal-bookmark
					span Planos
				router-link.options(to="/dashboard/bills").d-flex.flex-column.w-100
					.bi.bi-receipt
					span Faturas
				router-link.options(to="/dashboard/partners-api").d-flex.flex-column.w-100
					.bi.bi-people-fill
					span Parceiros
				router-link.options(to="/dashboard/schedules").d-flex.flex-column.w-100
					.bi.bi-clipboard
					span Agendamentos
		.right-content
			router-view
</template>

<script>
export default {
  data() {
    return {
      users: Object,
    };
  },
};
</script>

<!-- eslint-disable no-tabs -->
<style lang="stylus" scoped>
.dashboard-view

.left-navbar
	height: 100dvh
	min-width: 120px !important
	-webkit-box-shadow: 10px 0px 10px -1px rgba(0,0,0,0.51);
	-moz-box-shadow: 10px 0px 10px -1px rgba(0,0,0,0.51);
	box-shadow: 10px 0px 10px -1px rgba(0,0,0,0.51);
.right-content
	height: 100dvh
	width: 95%

a
	text-decoration: none
	color: #333342
	&:hover
		color: #ABA18D
.bi
	font-size: 50px
.options
	border-bottom: 1px solid #ABA18D;
	padding-bottom: 1rem
</style>
