<!-- eslint-disable max-len -->
<!-- eslint-disable no-tabs -->
<template>
<div class="bills-view mt-5">
  <h1 class="container text-start">Faturas:</h1>
  <table-component :columns="columns" class="text-start">
    <template v-slot:default>
      <tr v-for="(bill, index) in bills" :key="index">
        <td>{{ bill.id }}</td>
        <td>{{ bill.customer.name }}</td>
        <td>{{ bill.customer.email }}</td>
        <td>{{ bill.charges[0].payment_method.name }}</td>
        <td>
          <span v-if="bill.due_at">{{ converterData(bill.due_at) }}</span>
          <span v-else>semdata</span>
        </td>
        <td>{{ bill.bill_items[0].product.name }}</td>
        <td>
          <span v-if="bill.status == 'paid'">Pago</span>
          <span v-else-if="bill.status == 'pending'">Pendente</span>
          <span v-else>Cancelado</span>
        </td>
        <td class="text-center">
          <a class="btn btn-primary" :href="bill.url" target="_blank">
            <span class="bi bi-info-circle"> Informações
            </span>
          </a>
        </td>
      </tr>
    </template>
  </table-component>
  <Loading v-if="!isLoading"/>
</div>
</template>

<script>
import axios from 'axios';
import Loading from '@/components/molecules/Loading.vue';
import TableComponent from '../../../ui/TableComponent.vue';

export default {
  data() {
    return {
      bills: [],
      isLoading: false,
      columns: [
        { label: 'ID', field: 'id' },
        { label: 'Nome', field: 'name' },
        { label: 'Email', field: 'email' },
        { label: 'Método', field: 'method' },
        { label: 'Data', field: 'date' },
        { label: 'Plano', field: 'splan' },
        { label: 'Status', field: 'status' },
        { label: 'Ações', field: 'actions' },
      ],
    };
  },

  components: { Loading, TableComponent },

  beforeCreate() {
    this.isLoading = false;
    const token = localStorage.getItem('token');
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
    axios
      .get('https://api.asemego.com.br/api/faturas')
      .then((res) => {
        this.bills = res.data.bills;
      })
      .catch(() => {
      }).finally(() => {
        this.isLoading = true;
      });
  },
  methods: {
    converterData(data) {
      const dataa = data.slice(0, 10);
      const parts = dataa.split('-');
      const formattedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;
      return formattedDate;
    },
  },
};
</script>

<!-- eslint-disable no-tabs -->
<style lang="stylus" scoped>
table td, table th {
  border-width: 1px;
  border-color: #333342;
  border-style: solid;
  padding: 5px;
}

tr:hover {
	background-color: lightcoral;
}
</style>
