<!-- eslint-disable vue/no-mutating-props -->
<!-- eslint-disable max-len -->
<!-- eslint-disable no-tabs -->
<template>
    <div>
        <!-- Modal -->
        <div class="modal" tabindex="-1" role="dialog" :class="{ 'show': showModal }">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" style="font-family: fantasy;"><i class="bi bi-person me-2"></i>ALTERAR IMAGEMS</h5>
                        <button type="button" class="btn btn-danger" @click="hideModal">
                            <span aria-hidden="true">X</span>
                        </button>
                    </div>
                    <div class="modal-body">
                      <form class="d-flex flex-column justify-content-center">

                        <div class="mb-3">
                          <label for="logo" class="form-label d-flex fw-bold">Alterar Imagem 1:</label>
                          <input ref="imageOneInput" type="file" accept="image/*" class="form-control" @change="onImageOneSelected">
                        </div>


                        <button type="button" class="btn btn-primary" @click="changeFirstImage"><i class="bi bi-person-circle me-2"></i>Alterar primeira imagem</button>

                        <div class="mb-3">
                          <img v-if="partner.imagem_1" class="my-2 images-partner" :src="convertLinkImage1(this.localUserId)"/>
                        </div>
                        
                        <div class="mb-3">
                          <label for="logo" class="form-label d-flex fw-bold">Alterar Imagem 2:</label>
                          <input ref="imageTwoInput" type="file" accept="image/*" class="form-control" @change="onImageTwoSelected">
                        </div>

                        <button type="button" class="btn btn-primary" @click="changeSecondImage"><i class="bi bi-person-circle me-2"></i>Alterar segunda imagem</button>
                        
                        <div class="mb-3">
                          <img v-if="partner.imagem_2" class="my-2 images-partner" :src="convertLinkImage2(this.localUserId)"/>
                        </div>

                      </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Loading v-if="isLoading"/>
</template>

<!-- eslint-disable no-console -->
<script>
import axios from 'axios';
import Loading from '../molecules/Loading.vue';

export default {
  data() {
    return {
      isLoading: false,
      showModal: false,
      imageOneSelected: File,
      imageTwoSelected: File,
      localUserId: null,
      partner: {},
    };
  },
  components: { Loading },
  props: {
    id: String,
  },
  methods: {
    getImagesById() {
      this.isLoading = true;
      axios
      .get(`https://api.asemego.com.br/api/parceiros/${this.localUserId}`)
      .then((res) => {
        // eslint-disable-next-line prefer-destructuring
        this.partner = res.data;
      })
      .catch(() => {})
      .finally(() => {
        this.isLoading = false;
      });
    },
    convertLinkImage1(localUserId) {
      const link = `https://api.asemego.com.br/api/parceiros/${localUserId}/imagem?imagem=1`;
      return link;
    },
    convertLinkImage2(localUserId) {
      const link = `https://api.asemego.com.br/api/parceiros/${localUserId}/imagem?imagem=2`;
      return link;
    },
    hideModal() {
      this.showModal = false;
    },
    showModalChangePartnerImage(id) {
      this.localUserId = id;
      this.getImagesById();
      this.showModal = true;
    },
    onImageOneSelected(event) {
      // eslint-disable-next-line prefer-destructuring
      this.imageOneSelected = event.target.files[0];
    },
    onImageTwoSelected(event) {
      // eslint-disable-next-line prefer-destructuring
      this.imageTwoSelected = event.target.files[0];
    },
    changeFirstImage() {
      this.isLoading = true;
      const formData = new FormData();
      formData.append('imagem_1', this.imageOneSelected);

      const token = localStorage.getItem('token');
      if (token) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      }

      axios.post(`https://api.asemego.com.br/api/parceiros/${this.localUserId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(() => {
          this.$refs.imageOneInput.value = '';
          this.$refs.imageTwoInput.value = '';
        })
        .catch(() => {
        })
        .finally(() => {
          this.isLoading = false;
          this.hideModal();
        });
    },
    changeSecondImage() {
      this.isLoading = true;
      const formData = new FormData();
      formData.append('imagem_2', this.imageTwoSelected);

      const token = localStorage.getItem('token');
      if (token) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      }

      axios.post(`https://api.asemego.com.br/api/parceiros/${this.localUserId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(() => {
          this.$refs.imageOneInput.value = '';
          this.$refs.imageTwoInput.value = '';
        })
        .catch(() => {
        })
        .finally(() => {
          this.isLoading = false;
          this.hideModal();
        });
    },
  },
};
</script>
<!-- eslint-disable no-tabs -->
<style scoped>
.modal {
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal.show {
  display: block;
}

.modal-dialog {
  margin-top: 10%;
}

.modal-content {
  background-color: #fff;
}

.modal-header {
    background-color: #333342;
    color: #FFF;
    font-weight: bold;
}

.img-asemego {
    width: 240px;
}

.btn-primary {
  background-color: #333342 !important;
  border-color: #333342 !important;
}

.images-partner {
  max-width: 300px;
  height: 300px;
  border: #333342 2px solid;
}

</style>
