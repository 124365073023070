<!-- eslint-disable max-len -->
<template>
<div class="registry-card" v-if="isLoaded">
	<div class="containere">
		<div class="carde">
			<div class="card-innere" :class="{ rotated: isDivClicked }" @click="toggleDiv">
				<div class="front">
					<img src="https://i.ibb.co/PYss3yv/map.png" class="map-img">
					<div class="d-flex justify-content-center mt-1">
						<img class="img-ase" src="@/assets/wp/asemego-card.png">
					</div>
                    <div class="d-flex">
						<div class="d-flex flex-row justify-content-center">
                            <div class="image-bordered">
                                <div class="d-flex flex-column justify-content-center w-100">
							        <img :src="`${this.link}${this.idToGetImage}?cache=${cacheBuster}`">
                                </div>
                            </div>
						</div>
					</div>
					<div class="d-flex justify-content-between w-100 rowe card-no mt-2">
						<div class="d-flex justify-content-between w-100 ">
                            <div class="d-flex flex-column justify-content-end">
							    <span> {{ this.numero_cartao }} </span>
                            </div>
                            <div class="d-flex flex-column justify-content-end">
                                <span class="cargo" v-if="this.cargo == 'Assinatura Professor'">Professor</span>
                                <span class="cargo-else" v-else>Servidor da educação</span>
                            </div>
						</div>
					</div>
					<div class="rowe name">
						<span> {{ this.nome }} </span>
						<span v-if="this.status == 'pending'" style="color: red; font-weight: bold">INATIVO</span>
                        <span v-else style="color: green; font-weight: bold">ATIVO</span>
					</div>
				</div>
				<div class="back">
					<img src="https://i.ibb.co/PYss3yv/map.png" class="map-img">
					<div class="bar"></div>
					<div class="rowe card-cvv">
						<div>
								<img src="https://i.ibb.co/S6JG8px/pattern.png">
						</div>
					</div>
					<div class="d-flex flex-column justify-content-center card-text">
						<p>Carteirinha ASEMEGO - CONVÊNIOS</p>
                        <p>CLUBE DE VANTAGENS E BENEFÍCIOS</p>
					</div>
					<div class="rowe signature">
						<p style="font-family: 'Dancing Script', cursive;"> {{ this.nome }}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<Loading v-else/>
</template>

<script>
import axios from 'axios';
import Loading from '../../../components/molecules/Loading.vue';

export default {
    data() {
        return {
            isLoaded: false,
            nome: '',
            numero_cartao: '',
            isDivClicked: false,
            bills: '',
            idz: '',
            idToGetImage: '',
            cargo: '',
            status: '',
            link: 'https://api.asemego.com.br/api/usuarios/imagem/',
            cacheBuster: 0,
        };
    },

    components: { Loading },

    beforeCreate() {
        this.isLoading = false;
        this.idz = localStorage.getItem('id_vindi');
        const token = localStorage.getItem('token');
        if (token) {
            axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        }
        axios
            .post('https://api.asemego.com.br/api/faturacliente', {
            id: this.idz,
            })
            .then((res) => {
                this.bills = res.data.data.bills;
                const lastBill  = this.bills[this.bills.length - 1];
                this.cargo = lastBill.bill_items[0].product.name;
                this.status = lastBill.charges[0].status;
                this.isLoaded = true;
            })
            .catch(() => {
            })
            .finally(() => {
                this.isLoading = true;
            });
    },
    
    mounted() {
        this.nome = localStorage.getItem('nome');
        this.numero_cartao = localStorage.getItem('carteirinha');
        this.idToGetImage = localStorage.getItem('id');
        this.randomizeCacheBuster();
    },

    methods: {
        toggleDiv() {
            this.isDivClicked = !this.isDivClicked;
        },
        randomizeCacheBuster() {
            this.cacheBuster = Math.floor(Math.random(2) * 999); // Número aleatório entre 0 e 999
        },
    },


};
</script>

<!-- eslint-disable no-tabs -->
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=josefin+Sans:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Josefin Sans', sans-serif;
}

.rotated {
  transform: rotateY(-180deg);
}
.containere {
    min-height: 60vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-bordered {
    border: 5px solid white;
    border-radius: 10px;
    height: 90px !important;
    width: 90px !important;
}

.image-bordered img {
    border-radius: 5px;
}

.carde {
    width: 500px;
    height: 300px;
    color: #fff;
    cursor: pointer;
    perspective: 1000px;
}

.card-innere {
    width: 100%;
    height: 100%;
    position: relative;
    transition: transform 1s;
    transform-style: preserve-3d;
}

.front, .back {
    width: 100%;
    height: 100%;
    background-color: #001F5B;
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px 30px;
    border-radius: 15px;
    overflow: hidden;
    z-index: 1;
    backface-visibility: hidden;
}

.rowe {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cargo {
    font-size: 22px;
}

.cargo-else {
    font-size: 20px;
}

.map-img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    z-index: -1;
}

.card-no {
    font-size: 22px;
}

.name {
    font-size: 22px;
}

.bar {
    background: #222;
    margin-left: -30px;
    margin-right: -30px;
    height: 60px;
    margin-top: 10px;
}

.card-cvv {
    margin-top: 20px;
}

.card-cvv div {
    flex: 1;
}

.card-cvv img {
    width: 100%;
    display: block;
    line-height: 0;
}

.card-cvv p {
    background: #fff;
    color: #000;
    font-size: 22px;
    padding: 10px 20px;
}

.card-text {
    margin-top: 30px;
    font-size: 14px;
}

.signature {
    margin-top: 20px;
}

.back {
    transform: rotateY(180deg);
}

/* .carde:hover .card-innere:hover {
    transform: rotateY(-180deg);
} */

.img-ase {
	width: 180px !important;
	height: 60px !important;
}

@media screen and (max-width: 768px){
	.carde {
		transform: rotate(-90deg);
	}

	.containere {
		min-height: 95vh !important;
	}

	:global(.right-content) {
		height: 100% !important;
	}
}
</style>
