<!-- eslint-disable no-mixed-spaces-and-tabs -->
<!-- eslint-disable max-len -->
<template>
	<div class="terms-view">
		<div class="container my-5">
			<div class="about-us">
                <div class="d-flex justify-content-center flex-row text-start">
                    <h3 class="mb-4 fw-bold">Termo de Política e Responsabilidade da ASEMEGO para Proteção de Dados:</h3>
                </div>
				<p class="text-start">A Associação dos Servidores da Educação dos Municípios e do Estado de Goiás (ASEMEGO) reafirma seu compromisso com a proteção de dados pessoais de seus associados e colaboradores, em total consonância com os preceitos estabelecidos pela Lei Geral de Proteção de Dados (LGPD). Nesse sentido, desenvolvemos este Termo de Política e Responsabilidade para garantir a segurança e a privacidade das informações compartilhadas conosco.</p>
				<p class="text-start"><strong>Coleta de Dados: </strong>A ASEMEGO coleta informações pessoais de seus associados e colaboradores somente quando estritamente necessárias para a prestação de serviços, como adesão à associação, gestão de benefícios, comunicação institucional e demais atividades relacionadas às suas finalidades estatutárias.</p>
				<p class="text-start"><strong>Utilização de Dados: </strong> Os dados pessoais coletados pela ASEMEGO são utilizados exclusivamente para os fins declarados no momento da coleta ou para finalidades compatíveis com estas, sempre respeitando os direitos e interesses dos titulares dos dados.</p>
				<p class="text-start"><strong>Compartilhamento de Dados: </strong> Comprometemo-nos a não compartilhar, vender ou ceder de qualquer forma os dados pessoais de nossos associados e colaboradores a terceiros, exceto nos casos em que haja consentimento expresso ou quando necessário para cumprimento de obrigações legais.</p>
				<p class="text-start"><strong>Segurança de Dados: </strong>Implementamos medidas técnicas e organizacionais adequadas para garantir a segurança, integridade e confidencialidade dos dados pessoais sob nossa responsabilidade, protegendo-os contra acessos não autorizados, uso indevido, perda ou destruição.</p>
				<p class="text-start"><strong>Direitos dos Titulares: </strong>Reconhecemos e respeitamos os direitos dos titulares dos dados, conforme estabelecido pela LGPD, incluindo o direito de acesso, retificação, exclusão, portabilidade e revogação do consentimento.</p>
				<p class="text-start"><strong>Atualização do Termo: </strong>Este Termo de Política e Responsabilidade poderá ser atualizado periodicamente para refletir mudanças em nossas práticas de privacidade ou em decorrência de novas exigências legais. As versões anteriores serão arquivadas para consulta dos interessados.</p>
				<p class="text-start">Ao associar-se à ASEMEGO ou ao colaborar conosco de qualquer forma, você concorda e aceita as disposições deste Termo de Política e Responsabilidade para Proteção de Dados. Estamos comprometidos em agir com transparência, ética e responsabilidade no tratamento de suas informações pessoais, visando sempre garantir sua privacidade e segurança.</p>
			</div>
		</div>
	</div>
</template>

<!-- eslint-disable no-mixed-spaces-and-tabs -->
<style scoped>
h3 {
    text-decoration: underline;
}

@media screen and (max-width: 768px) {
    h3 {
        font-size: 24px;
    }
}
</style>
