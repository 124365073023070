<!-- eslint-disable max-len -->
<!-- eslint-disable no-tabs -->
<template>
<div class="plans-view mb-5">
    <div class="container">
        <div class="title-info d-flex flex-row justify-content-center my-5">
            <h2>Escolha o seu plano</h2><button class="btn btn-outline-info ms-3" type="button" @click="stepPagePlan = 0" v-if="stepPagePlan === 1 || stepPagePlan === 2"><i class="bi bi-arrow-left">Voltar</i></button></div>
        <div class="select-plan d-flex flex-row w-100 justify-content-center" v-if="stepPagePlan == 0">
            <div class="teacher-plan me-4"><img class="card img-card" src="@/assets/plan-teacher.png" @click="stepPagePlan = 1" /></div>
            <div class="administration-plan"><img class="card img-card" src="@/assets/plan-admin.png" @click="stepPagePlan = 2" /></div>
        </div>
        <div class="teacher-plan-info w-100 d-flex flex-column my-4" v-if="stepPagePlan == 1">
            <div class="centralize d-flex flex-row">
                <div class="left-side-info w-50"><img class="img-cleaned" src="@/assets/professor.png" /></div>
                <div class="right-side-info w-50 text-start ps-2">
                    <h4>Assinatura Professor</h4>
                    <div class="descriptions d-flex flex-row"><span><strong>Tipo de convênio:</strong></span><span class="ms-2">Físico</span></div>
                    <div class="descriptions d-flex flex-row"><span><strong>Abrangência:</strong></span><span class="ms-2">Nacional</span></div>
                    <div class="value-price my-3"><span class="text-green fw-bold fs-1">R$45,00</span><span>/ mês</span></div>
                    <div class="advantages-plan-info">
                        <div class="d-flex flex-row">
                            <p><strong>Clube de Vantagens</strong> e descontos em vários estabelecimentos.</p>
                        </div>
                        <div class="d-flex flex-row">
                            <p><strong>Assessoria Jurídica</strong> em âmbito educacional.</p>
                        </div>
                        <div class="d-flex flex-row">
                            <p><strong>Coworking:</strong> para auxiliar os diretores nas demandas externas, bem como os demais servidores que procuram um espaço que atenda suas necessidades para auxiliá-las em seu trabalho.</p>
                        </div>
                        <div class="d-flex flex-row">
                            <p><strong>Espaço para divulgação:</strong> dos empreendimentos dos servidores (virtual – mídias digitais e presencial – feirinha do servidor)</p>
                        </div>
                        <div class="d-flex flex-row">
                            <p><strong>Sala de Reuniões:</strong> Espaço de bem estar e beleza, Espaço multiprofissional, Happy hours do servidor, espaço gourmet, ambiente informatizado.</p>
                        </div>
                    </div><a class="btn btn-success w-100 my-4" target="_blank" href="https://app.vindi.com.br/customer/pages/43587d64-1e98-4a35-9c3a-85229798e865/subscriptions/new"><i class="bi bi-wallet-fill me-2"></i><span>Associar agora</span></a></div>
            </div>
        </div>
        <div class="administration-plan-info w-100" v-if="stepPagePlan == 2">
            <div class="centralize d-flex flex-row">
                <div class="left-side-info w-50"><img class="img-cleaned img-margin" src="@/assets/administrator.png" /></div>
                <div class="right-side-info w-50 text-start ps-2">
                    <h4>Assinatura Administrativo</h4>
                    <div class="descriptions d-flex flex-row"><span><strong>Tipo de convênio:</strong></span><span class="ms-2">Físico</span></div>
                    <div class="descriptions d-flex flex-row"><span><strong>Abrangência:</strong></span><span class="ms-2">Nacional</span></div>
                    <div class="value-price my-3"><span class="text-green fw-bold fs-1">R$25,00</span><span>/ mês</span></div>
                    <div class="advantages-plan-info">
                        <div class="d-flex flex-row">
                            <p><strong>Clube de Vantagens</strong> e descontos em vários estabelecimentos.</p>
                        </div>
                        <div class="d-flex flex-row">
                            <p><strong>Assessoria Jurídica</strong> em âmbito educacional.</p>
                        </div>
                        <div class="d-flex flex-row">
                            <p><strong>Coworking:</strong> para auxiliar os diretores nas demandas externas, bem como os demais servidores que procuram um espaço que atenda suas necessidades para auxiliá-las em seu trabalho.</p>
                        </div>
                        <div class="d-flex flex-row">
                            <p><strong>Espaço para divulgação:</strong> dos empreendimentos dos servidores (virtual - mídias digitais e presencial - feirinha do servidor)</p>
                        </div>
                        <div class="d-flex flex-row">
                            <p><strong>Sala de Reuniões:</strong> Espaço de bem estar e beleza, Espaço multiprofissional, Happy hours do servidor, espaço gourmet, ambiente informatizado.</p>
                        </div>
                    </div><a class="btn btn-success w-100 my-4" target="_blank" href="https://app.vindi.com.br/customer/pages/43587d64-1e98-4a35-9c3a-85229798e865/subscriptions/new"><i class="bi bi-wallet-fill me-2"></i><span>Associar agora</span></a></div>
            </div>
        </div>
        <div class="descripton-of-services d-flex flex-row justify-content-center" v-if="stepPagePlan &gt; 0">
            <div class="content-center d-flex flex-column mt-2">
                <p class="text-start"><strong>A ASSOCIAÇÃO DOS SERVIDORES DA EDUCAÇÃO DOS MUNICÍPIOS E DO ESTADO DE GOIÁS,</strong> tem por finalidade a representação da classe profissional dos servidores públicos estaduais e municipais dentro do território do Estado de Goiás,
                    procurando zelar pelos interesses e pelo bem-estar geral de seus associados, propondo-se a exercer todas e quaisquer atividades necessárias para tanto, entre elas:</p>
                <ul class="text-start my-3">
                    <li class="mb-2"><span>Amparar e prestigiar os associados dentro dos princípios constitucionais vigentes, podendo representá-los judicial ou extrajudicialmente, perante as autoridades constituídas, nos seus interesses coletivos e individuais, nas suas condições sociais e de trabalho, valendo-se, para tanto, de todos os recursos pertinentes;</span></li>
                    <li class="mb-2"><span>Atuar em promoção dos interesses da classe associada, junto ao Poder Legislativo e ou executivo, mesmo que se cuidem de interesses individuais homogêneos de um grupo restrito de seus associados;</span></li>
                        <li class="mb-2"><span>Propor políticas, modelos e sugestões de melhorias das condições de trabalho de seus associados perante os órgãos e pessoas jurídicas de sua representação.</span></li>
                        <li class="mb-2"><span>Congregar os servidores das prefeituras dos diversos municípios goianos e de suas autarquias;</span></li>
                        <li class="mb-2"><span>Promover por todas as formas, inclusive mediante convênios com entidades ou profissionais especializados, a elevação das condições de saúde, educação, cultura, lazer e benefícios para seus associados e dependentes;</span></li>
                        <li class="mb-2"><span>Buscar condições e vantagens para os associados nas áreas de assistência social, previdência privada, planos de saúde ou seguro-saúde, seguros de ramos elementares e outros benefícios, podendo criar serviços próprios ou estabelecer convênios ou parcerias com terceiros, dependendo da peculiaridade de cada benefício.</span></li>
                </ul>
            </div>
        </div>
    </div>
</div>
</template>

<script>

export default {
  data() {
    return {
      stepPagePlan: 0,
    };
  },
};
</script>

<!-- eslint-disable no-tabs -->
<!-- eslint-disable max-len -->
<style scoped>

.select-plan {
	margin-bottom: 9rem;
	margin-top: 1rem;
}
.card {
	background: #ececec;
	box-shadow: rgba(0,0,0,0.4) 0px 2px 4px, rgba(0,0,0,0.3) 0px 7px 13px -3px;
	width: 400px;
	height: auto;
	border-radius: 15px;
	border: none;
}

.img-card {
	cursor: pointer;
}

.img-cleaned {
	width: 100%;
	height: auto;
}

p {
	margin-bottom: 0;
}

.descripton-of-services {
	border-top: 1px solid #000;
}

.teacher-plan, .administration-plan {
	transition: transform 0.3s ease;
}

.teacher-plan:hover, .administration-plan:hover {
	transform: scale(1.1);
	box-shadow: 0 0 1px rgba(0,0,0,0);
	transition-delay: 0.2s; /* Delay apenas no hover */
}

 @media screen and (max-width: 768px) {
	.select-plan {
		display: flex;
		flex-direction: column !important;
	}

	.select-plan .teacher-plan {
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin: 0 !important;
	}
	.select-plan .administration-plan {
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin-top: 1rem;
	}
	.centralize {
		display: flex;
		flex-direction: column !important;
	}
	.centralize .left-side-info {
		width: 100% !important;
	}
	.centralize .right-side-info {
		width: 100% !important;
		margin-top: 1rem;
	}
	.centralize .img-margin {
		margin-top: 1.5rem;
	}
}

</style>
