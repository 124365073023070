<!-- eslint-disable max-len -->
<!-- eslint-disable no-tabs -->
<template>
<div class="info-view my-5">
	<div class="container">
		<div class="card">
			<div class="card-body">
				<h2 class="card-title">Informações pessoais</h2>
        <div class="profile row">
          <div class="col-12 col-md-4">
            <div class="d-flex justify-content-start ms-4 profile-info-mobile">
              <a :href="`${this.link}${this.idz}?cache=${cacheBuster}`" target="_blank" class="me-3 img-link">
                <img :src="`${this.link}${this.idz}?cache=${cacheBuster}`" class="img-circle">
              </a>
              <div class="d-flex flex-column">
                <span class="fw-bold text-start">{{ this.user.nome }}</span>
                <span class="text-start">Associado</span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-8">
            <div class="d-flex flex-row margin-top-mb">
              <button class="btn btn-success me-2" @click="openEditDataModal(user)"><i class="bi bi-pencil-square me-1"></i>Editar dados</button>
              <ImageCropper @update-avatar="updateAvatar"/>
            </div>
          </div>
        </div>
				<div class="card-informations row mt-4 text-start ms-4">
					<div class="col-12 col-md-4">
						<span class="fw-bold">E-mail: </span>
            <span>{{ this.user.email }}</span>
					</div>
					<div class="col-12 col-md-4">
            <span class="fw-bold">Telefone: </span>
            <span>{{ this.user.phone }}</span>
					</div>
          <div class="col-12 col-md-4">
						<span class="fw-bold">Data de nascimento:</span>
            <span>{{ this.user.birthday }}</span>
					</div>
				</div>
				<div class="card-informations-line row text-start ms-4 mt-2">
					<div class="col-12 col-md-4">
						<span class="fw-bold">Endereço: </span>
            <span>{{ this.user.street }}</span>
					</div>
					<div class="col-12 col-md-4">
						<span class="fw-bold">Bairro: </span>
            <span>{{ this.user.neighborhood }}</span>
					</div>
					<div class="col-12 col-md-4">
						<span class="fw-bold">Numero: </span>
            <span>{{ this.user.number }}</span>
					</div>
				</div>
				<div class="card-informations-line row text-start ms-4 mt-2">
          <div class="col-12 col-md-4">
						<span class="fw-bold">Cidade: </span>
            <span>{{ this.user.city }}</span>
					</div>
          <div class="col-12 col-md-4">
						<span class="fw-bold">Estado: </span>
            <span>{{ this.user.state }}</span>
					</div>
					<div class="col-12 col-md-4">
						<span class="fw-bold">CEP: </span>
            <span>{{ this.user.zipcode }}</span>
					</div>
				</div>
        <div class="card-informations-line row text-start ms-4 mt-2">
          <div class="col-12 col-md-4">
						<span class="fw-bold">Detalhes adicionais: </span>
            <span>{{ this.user.additional_details }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<Loading v-if="!isLoading"/>
<EditProfileModal ref="EditProfileModal" :userData="user" @profile-updated="updateUserData" />
</template>

<script>
import axios from 'axios';
import Loading from '../../../components/molecules/Loading.vue';
import EditProfileModal from '../../../components/modals/EditProfileModal.vue';
import ImageCropper from '../../../components/atoms/ImageCropper.vue';

export default {
  data() {
    return {
      isLoading: false,
      idz: '',
      cacheBuster: 0,
      link: 'https://api.asemego.com.br/api/usuarios/imagem/',
      user: {
        additional_details: '',
        birthday: '',
        city: '',
        email: '',
        neighborhood: '',
        number: '',
        nome: '',
        phone: '',
        zipcode: '',
        street: '',
        state: '',
      },
      image: null,
    };
  },
  components: { Loading, EditProfileModal, ImageCropper },

  created() {
    this.isLoading = false;
    this.updateImage();
    this.idz = localStorage.getItem('id');
    const token = localStorage.getItem('token');
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
    axios
      .get(`https://api.asemego.com.br/api/usuarios/${this.idz}`, {
      })
      .then((res) => {
        this.idz = res.data.data.id;
        // eslint-disable-next-line prefer-destructuring
        const nome = res.data.data.nome;
        const carteirinha = res.data.data.number_id;
        localStorage.setItem('carteirinha', carteirinha);
        localStorage.setItem('nome', nome);
        this.user = res.data.data;
        sessionStorage.setItem('userData', JSON.stringify(res.data.data));
      })
      .catch(() => {
      })
      .finally(() => {
        this.isLoading = true;
      });
  },
  watch: {
    isModalOpen(newValue) {
      if (newValue) {
        this.$refs.EditProfileModal.showModalEditProfile();
      }
    },
  },
  methods: {
    loadDataProfile() {
      this.isLoading = false;
      this.idz = localStorage.getItem('id');
      const token = localStorage.getItem('token');
      if (token) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      }
      axios
        .get(`https://api.asemego.com.br/api/usuarios/${this.idz}`, {
        })
        .then((res) => {
          this.idz = res.data.data.id;
          // eslint-disable-next-line prefer-destructuring
          const nome = res.data.data.nome;
          const carteirinha = res.data.data.number_id;
          localStorage.setItem('carteirinha', carteirinha);
          localStorage.setItem('nome', nome);
          this.user = res.data.data;
          sessionStorage.setItem('userData', JSON.stringify(res.data.data));
        })
        .catch(() => {
        })
        .finally(() => {
          this.isLoading = true;
        });
    },
    openEditDataModal(user) {
      this.$refs.EditProfileModal.showModalEditProfile(user);
    },
    openChangeProfileImageModal(userId) {
      this.$refs.ChangeProfileImageModal.showModalChangePhotoProfile(userId);
    },
    updateUserData(updatedData) {
      this.userData = { ...this.userData, ...updatedData };
      sessionStorage.setItem('userData', JSON.stringify(this.userData));
      window.location.reload();
    },
    updateImage() {
      this.cacheBuster = Math.floor(Math.random(2) * 999); // Número aleatório entre 0 e 999
    },
    updateAvatar() {
      window.location.reload();
    },
  },
  mounted() {
    const storedUserData = sessionStorage.getItem('userData');
    if (storedUserData) {
      this.user = JSON.parse(storedUserData);
    }
  },
  computed: {
    userData() {
      return JSON.parse(sessionStorage.getItem('userData')) || {
        additional_details: '',
        birthday: '',
        city: '',
        email: '',
        neighborhood: '',
        number: '',
        nome: '',
        phone: '',
        zipcode: '',
        street: '',
        state: '',
      };
    },
  },
};
</script>

<style lang="stylus" scoped>

.img-circle
  border: 3px black solid;
  border-radius: 15px;
  width: 100px;
  height: 100px;
.card
  -webkit-box-shadow: 10px 10px 16px 1px rgba(0,0,0,0.37);
  -moz-box-shadow: 10px 10px 16px 1px rgba(0,0,0,0.37);
  box-shadow: 10px 10px 16px 1px rgba(0,0,0,0.37);

.img-link:hover
  transform: scale(1.05);
  cursor: pointer
.img-link
  transition: all .5s;

.margin-top-mb
  justify-content: end
@media screen and (max-width: 768px)
  .card-informations-line
    margin-top: 0 !important
  .profile-info-mobile
    justify-content: center !important
  .margin-top-mb
    margin-top: 1rem
    margin-bottom: 1rem
    display: block
    justify-content: center !important
  .img-circle
    border: 3px black solid;
    border-radius: 15px;
    width: 80px;
    height: 80px;
</style>
