<!-- eslint-disable max-len -->
<template>
  <div class="container">
    <div class="title-top d-flex flex-row justify-content-center mt-4">
      <router-link to="/schedules" class="btn btn-info mobile-back-button mb-3">
        <i class="bi bi-arrow-left"></i>
        Voltar
      </router-link>
      <h1 class="me-4">{{ schedule.nome }}</h1>
      <div class="d-flex">
        <router-link to="/schedules" class="btn btn-info back-button">
          <i class="bi bi-arrow-left"></i>
          Voltar
        </router-link>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-center">
      <div class="card-tag px-2 py-2">
        <span v-if="schedule.tipo == 'others'">Outros</span>
        <span v-else-if="schedule.tipo == 'health'">Saúde e Bem-estar</span>
        <span v-else-if="schedule.tipo == 'room'">Salas</span>
        <span v-else>Indústria</span>
      </div>
    </div>
    <div class="container-body">
      <img class="img-logo-partner-details first-image my-2" :src="getImage(schedule.id)" />
      <img class="img-logo-partner-details my-2" :src="getImage2(schedule.id)"/>
      <div class="d-flex flex-column justify-content-center">
        <div v-if="schedule.descricao != null" class="d-flex justify-content-center w-100">
          <div class="text-start w-50 description py-2 px-2">
            <span>
              {{ schedule.descricao }}
            </span>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row justify-content-center my-5 organize-mobile">
        <a
          v-if="schedule.telefone !== null"
          class="btn btn-info me-2 h-100"
          :href="schedule.telefone"
          target="_blank"
        >
          <i class="bi bi-telephone-fill me-1"></i>
          Telefone
        </a>
        <a
          v-if="schedule.whatsapp !== null"
          class="btn btn-success me-2"
          :href="schedule.whatsapp"
          target="_blank"
        >
          <i class="bi bi-telephone me-1"></i>
          Whatsapp
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      url: 'https://asemego.com.br/api/storage/app/',
      schedule: {},
    };
  },
  beforeCreate() {
    axios
      .get(`https://api.asemego.com.br/api/agendamentos/${this.id}`)
      .then((res) => {
        // eslint-disable-next-line prefer-destructuring
        this.schedule = res.data.agendamento;
      })
      .catch(() => {})
      .finally(() => {
        // eslint-disable-next-line no-console
      });
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  methods: {
    getImage(id) {
      const link = `https://api.asemego.com.br/api/agendamentos/${id}/imagem?number=1`;
      return link;
    },
    getImage2(id) {
      const link = `https://api.asemego.com.br/api/agendamentos/${id}/imagem?number=2`;
      return link;
    },
  },
};
</script>

<style scoped>

.description {
  font-weight: bold;
  color: #fff;
  background-color: #333342;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  -webkit-box-shadow: 0px 1px 11px 5px rgba(0,0,0,0.36);
  -moz-box-shadow: 0px 1px 11px 5px rgba(0,0,0,0.36);
  box-shadow: 0px 1px 11px 5px rgba(0,0,0,0.36);
  border-radius: 6px;
}
 .first-image {
	 margin-right: 1rem;
}
 .btn-info {
	 height: 35px;
}
 .back-button {
	 display: block !important;
}
 .mobile-back-button {
	 display: none !important;
}
 .card-tag {
	 border-radius: 6px;
	 background-color: #333342;
	 font-weight: bold;
	 color: #fff;
	 width: fit-content;
}
 .card-partner {
	 background: linear-gradient(to right, #eeb12f, #b38728, #eeb12f, #b38728, #eeb12f);
}
 .card-img-partner {
	 width: 40px !important;
	 height: 40px !important;
}
 .img-logo-partner-details {
	 max-height: 300px;
	 border-radius: 6px !important;
	 -webkit-box-shadow: 0px 1px 11px 5px rgba(0,0,0,0.36);
	 -moz-box-shadow: 0px 1px 11px 5px rgba(0,0,0,0.36);
	 box-shadow: 0px 1px 11px 5px rgba(0,0,0,0.36);
}
 @media screen and (max-width: 500px) {
	 .title-top {
		 flex-direction: column !important;
		 justify-content: center !important;
		 align-content: center;
		 align-items: center;
		 width: 100% !important;
	}
	 .btn-info {
		 width: 100% !important;
	}
	 .back-button {
		 display: none !important;
	}
	 .mobile-back-button {
		 display: block !important;
	}
	 .card-img-partner {
		 width: 40px !important;
		 height: 40px !important;
	}
	 .card-tag {
		 width: fit-content;
	}
	 .img-logo-partner-details {
		 width: 200px;
		 height: 200px;
		 border: 1px solid #000 !important;
		 border-radius: 15px !important;
	}
	 .organize-mobile {
		 display: flex !important;
		 justify-content: center !important;
		 flex-direction: column !important;
	}
	 .organize-mobile > * {
		 margin-top: 1rem;
	}
	 .first-image {
		 margin-right: 0;
	}
}
 
</style>
