<!-- eslint-disable no-tabs -->
<template lang="pug">
.account-view
	.d-flex.flex-row.mobile-responsive
		.left-navbar.d-flex.flex-column(style="color: #333342;")
			.menu-options.text-center.my-3
				router-link.options(to="/account/info").d-flex.flex-column.w-100
					.bi.bi-info-circle
					span Informações Pessoais
				router-link.options(to="/account/bills").d-flex.flex-column.w-100
					.bi.bi-receipt-cutoff
					span Faturas
				router-link.options(to="/account/littlecard").d-flex.flex-column.w-100
					.bi.bi-person-vcard
					span Carteirinha
		.right-content
			router-view
</template>

<script>

export default {
  data() {
    return {
      token: '',
      users: Object,
      isLoading: false,
      isValid: false,
    };
  },
  beforeCreate() {
    this.isLoading = true;
    this.token = localStorage.getItem('token');
    if (this.token.length > 0) {
      this.isValid = true;
    } else {
      this.isValid = false;
    }
  },
};
</script>

<!-- eslint-disable no-tabs -->
<style lang="stylus" scoped>
.left-navbar
	height: 100vh
	width: 5%
	-webkit-box-shadow: 10px 0px 10px -1px rgba(0,0,0,0.51);
	-moz-box-shadow: 10px 0px 10px -1px rgba(0,0,0,0.51);
	box-shadow: 10px 0px 10px -1px rgba(0,0,0,0.51);
	min-width: 100px
.right-content
	height: 100vh
	width: 95%

a
	text-decoration: none
	color: #333342
	&:hover
		color: #ABA18D
.bi
	font-size: 50px
.options
	border-bottom: 1px solid #ABA18D;
	padding-bottom: 1rem

@media screen and (max-width: 768px)
	.mobile-responsive
		flex-direction: column !important
		.left-navbar
			flex-direction: row !important
			width: 100%
			height: 150px
			.menu-options
				display: flex
				width: 100%
				.options
					border-bottom: 0
		.right-content
			width: 100%
</style>
