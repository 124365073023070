<!-- eslint-disable max-len -->
<!-- eslint-disable no-tabs -->

<template>
  <div class="partners-view mt-5 container">
    <h1 class="text-start">Agendamentos:</h1>
    <div class="action-buttons d-flex flex-row justify-content-start">
      <button class="btn btn-primary mt-2" @click="openNewScheduleModal">
        <i class="bi bi-person-fill-add me-2"></i>Adicionar Agendamento
      </button>
    </div>
    <table-component
      :columns="columns"
      class="text-start mt-4"
      v-if="agendamentos.length > 0"
    >
      <template v-slot:default>
        <tr v-for="(agendamento, index) in this.agendamentos" :key="index">
          <td>{{ agendamento.id }}</td>
          <td>{{ agendamento.nome }}</td>
          <td>{{ agendamento.tipo }}</td>
          <td>{{ agendamento.telefone }}</td>
          <td>
            <span v-if="agendamento.imagem != 'null' || agendamento.imagem.length > 0 || agendamento.imagem != ''">CONTÉM</span>
            <span v-else class="fw-bold">X</span>
          </td>
          <td>
            <span v-if="agendamento.descricao != 'null' || agendamento.descricao.length > 0 || agendamento.descricao != ''">CONTÉM</span>
            <span v-else class="fw-bold">X</span>
          </td>
          <td>
            <div class="d-flex flex-row justify-content-start">
              <button
                type="button"
                class="btn btn-primary me-2"
                @click="openEditScheduleModal(agendamento)"
              >
                <i class="bi bi-pen-fill"></i>
              </button>
              <button
                type="button"
                class="btn btn-danger"
                @click="openDeleteScheduleModal(agendamento)"
              >
                <i class="bi bi-trash"></i>
              </button>
            </div>
          </td>
        </tr>
      </template>
    </table-component>
    <div
      v-if="agendamentos.length === null || agendamentos.length == 0"
      class="container d-flex flex-row justify-content-center"
    >
      <div class="d-flex flex-column justify-content-center">
        <h2>Não temos agendamentos inseridos do sistema!</h2>
        <div class="d-flex justify-content-center">
          <img src="../../../assets/ui/not-found-fatura.png" class="w-50" />
        </div>
      </div>
    </div>
    <Loading v-if="isLoading" />
    <InsertNewSchedule ref="InsertNewSchedule" @created="closeNewScheduleModal"/>
    <DeleteScheduleModal ref="DeleteScheduleModal" @deleted="deletedSchedule" :agendamento="agendamentoSelected"/>
    <EditScheduleModal  ref="EditScheduleModal" @edited="editedSchedule" :agendamento="agendamentoSelected"/>
  </div>
</template>

<!-- eslint-disable no-console -->
<script>
import axios from 'axios';
import TableComponent from '../../../ui/TableComponent.vue';
import Loading from '../../../components/molecules/Loading.vue';
import InsertNewSchedule from '../../../components/modals/InsertNewSchedule.vue';
import DeleteScheduleModal from '../../../components/modals/DeleteScheduleModal.vue';
import EditScheduleModal from '../../../components/modals/EditScheduleModal.vue';

export default {
  name: 'PartnersView',
  components: {
    TableComponent,
    Loading,
    InsertNewSchedule,
    DeleteScheduleModal,
    EditScheduleModal,
  },
  data() {
    return {
      isLoading: false,
      agendamentos: [],
      agendamentoSelected: {},
      columns: [
        { label: 'ID', field: 'id' },
        { label: 'Nome', field: 'name' },
        { label: 'Tipo', field: 'type' },
        { label: 'Telefone', field: 'telefone' },
        { label: 'Imagem', field: 'imagens' },
        { label: 'Descrição', field: 'descricao' },
        { label: 'Ações', field: 'acoes' },
      ],
    };
  },
  beforeCreate() {
    this.isLoading = true;
    axios
      .get('https://api.asemego.com.br/api/agendamentos')
      .then((res) => {
        this.agendamentos = res.data.agendamentos;
      })
      .catch(() => {})
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    toLoadTablePartner() {
      this.isLoading = true;
      axios
        .get('https://api.asemego.com.br/api/agendamentos')
        .then((res) => {
          this.agendamentos = res.data.agendamentos;
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    openNewScheduleModal() {
      this.$refs.InsertNewSchedule.showModalWithForm();
    },
    closeNewScheduleModal() {
      this.$refs.InsertNewSchedule.hideModal();
      this.toLoadTablePartner();
    },
    openDeleteScheduleModal(agendamento) {
      this.agendamentoSelected = agendamento;
      this.$refs.DeleteScheduleModal.showModalToDelete();
    },
    deletedSchedule() {
      this.$refs.DeleteScheduleModal.hideModal();
      this.toLoadTablePartner();
    },
    openEditScheduleModal(agendamento) {
      this.agendamentoSelected = agendamento;
      this.$refs.EditScheduleModal.updateImage();
      this.$refs.EditScheduleModal.showModalWithEditData(this.agendamentoSelected);
    },
    editedSchedule() {
      this.$refs.EditScheduleModal.hideModal();
      this.toLoadTablePartner();
    },
  },
};
</script>
<!-- eslint-disable no-tabs -->
<style scoped>
.partners-view {
  margin-bottom: 1rem;
}

table td,
table th {
  border-width: 1px;
  border-color: #333342;
  border-style: solid;
  padding: 5px;
}

tr:hover {
  background-color: red;
}
</style>
