<!-- eslint-disable vue/no-mutating-props -->
<!-- eslint-disable max-len -->
<!-- eslint-disable no-tabs -->
<template>
    <div>
        <!-- Modal -->
        <div class="modal" tabindex="-1" role="dialog" :class="{ 'show': showModal }">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title"><i class="bi bi-person me-2"></i>EDITAR INFORMAÇÕES</h5>
                        <button type="button" class="btn btn-danger" @click="hideModal" data-bs-dismiss="modal">
                            <span aria-hidden="true">X</span>
                        </button>
                    </div>
                    <div class="modal-body">
                      <form @submit.prevent="editProfile" class="d-flex flex-column justify-content-center">

                        <div class="mb-3">
                        <label for="telefone" class="form-label d-flex fw-bold">Nome:</label>
                        <input type="tel" id="telefone" name="telefone" v-model="formProfileEdit.nome" class="form-control" maxlength="20" minlength="5" required>
                        </div>

                        <div class="mb-3">
                        <label for="telefone" class="form-label d-flex fw-bold">Email:</label>
                        <input type="tel" id="telefone" name="telefone" v-model="formProfileEdit.email" class="form-control" disabled>
                        </div>

                        <div class="mb-3">
                        <label for="telefone" class="form-label d-flex fw-bold">Telefone:</label>
                        <input type="tel" id="telefone" name="telefone" v-model="formProfileEdit.phone" class="form-control" required>
                        </div>

                        <div class="mb-3">
                        <label for="data_nascimento" class="form-label d-flex fw-bold">Data de Nascimento:</label>
                        <input type="text" id="data_nascimento" name="data_nascimento" v-model="formProfileEdit.birthday" class="form-control" required>
                        </div>

                        <div class="mb-3">
                        <label for="endereco" class="form-label d-flex fw-bold">Endereço:</label>
                        <input type="text" id="endereco" name="endereco" v-model="formProfileEdit.street" class="form-control" required>
                        </div>

                        <div class="mb-3">
                        <label for="bairro" class="form-label d-flex fw-bold">Bairro:</label>
                        <input type="text" id="bairro" name="bairro" v-model="formProfileEdit.neighborhood" class="form-control" required>
                        </div>

                        <div class="mb-3">
                        <label for="bairro" class="form-label d-flex fw-bold">Número:</label>
                        <input type="text" id="bairro" name="bairro" v-model="formProfileEdit.number" class="form-control" required>
                        </div>

                        <div class="mb-3">
                        <label for="bairro" class="form-label d-flex fw-bold">Detalhes adicionais:</label>
                        <input type="text" id="bairro" name="bairro" v-model="formProfileEdit.additional_details" class="form-control" required>
                        </div>

                        <div class="mb-3">
                        <label for="estado" class="form-label d-flex fw-bold">Cidade:</label>
                        <input type="text" id="cidade" name="cidade" v-model="formProfileEdit.city" class="form-control" required>
                        </div>

                        <div class="mb-3">
                        <label for="estado" class="form-label d-flex fw-bold">Estado:</label>
                        <input type="text" id="estado" name="estado" v-model="formProfileEdit.state" class="form-control" required>
                        </div>

                        <div class="mb-3">
                        <label for="cep" class="form-label d-flex fw-bold">CEP:</label>
                        <input type="text" id="cep" name="cep" v-model="formProfileEdit.zipcode" class="form-control" required>
                        </div>

                        <button type="submit" class="btn btn-primary"><i class="bi bi-pencil-square me-2"></i>Inserir parceiro</button>
                      </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Loading v-if="isLoading" />
</template>

<!-- eslint-disable no-console -->
<script>
import axios from 'axios';
import Loading from '../molecules/Loading.vue';

export default {
  data() {
    return {
      isLoading: false,
      showModal: false,
      formProfileEdit: {},
    };
  },
  components: { Loading },
  emits: ['profile-updated'],
  props: {
    userData: Object,
  },
  methods: {
    hideModal() {
      this.showModal = false;
    },
    showModalEditProfile(userData) {
      this.formProfileEdit = { ...userData };
      this.showModal = true;
    },
    editProfile() {
      this.isLoading = true;
      const formData = new FormData();

      formData.append('additional_details', this.formProfileEdit.additional_details);
      formData.append('birthday', this.formProfileEdit.birthday);
      formData.append('city', this.formProfileEdit.city);
      formData.append('neighborhood', this.formProfileEdit.neighborhood);
      formData.append('number', this.formProfileEdit.number);
      formData.append('name', this.formProfileEdit.nome);
      formData.append('phone', this.formProfileEdit.phone);
      formData.append('zipcode', this.formProfileEdit.zipcode);
      formData.append('street', this.formProfileEdit.street);
      formData.append('state', this.formProfileEdit.state);

      const token = localStorage.getItem('token');
      if (token) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      }

      axios.post(`https://api.asemego.com.br/api/usuarios/${this.formProfileEdit.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(() => {
          this.$emit('profile-updated', this.userData);
        })
        .catch(() => {
        })
        .finally(() => {
          this.isLoading = false;
          this.hideModal();
        });
    },
  },
};
</script>
<!-- eslint-disable no-tabs -->
<style scoped>
.modal {
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal.show {
  display: block;
}

.modal-dialog {
  margin-top: 10%;
}

.modal-content {
  background-color: #fff;
}

.modal-header {
    background-color: #333342;
    color: #FFF;
    font-weight: bold;
}

.img-asemego {
    width: 240px;
}

.btn-primary {
  background-color: #333342 !important;
  border-color: #333342 !important;
}
</style>
