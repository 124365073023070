<!-- eslint-disable max-len -->
<!-- eslint-disable no-tabs -->
<template>
    <div>
        <!-- Modal -->
        <div class="modal" tabindex="-1" role="dialog" :class="{ 'show': showModal }">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" style="font-family: fantasy;"><i class="bi bi-person me-2"></i>INSERIR PARCEIRO</h5>
                        <button type="button" class="btn btn-danger" @click="hideModal">
                            <span aria-hidden="true">X</span>
                        </button>
                    </div>
                    <div class="modal-body">
                      <form @submit.prevent="insertPartner" class="d-flex flex-column justify-content-center">
                        <div class="mb-3">
                          <label for="nome" class="form-label d-flex fw-bold">Nome:</label>
                          <input type="text" v-model="formData.nome" class="form-control" required>
                        </div>
                        <div class="mb-3">
                          <label for="tag" class="form-label d-flex fw-bold">Tag:</label>
                          <select v-model="formData.tag" class="form-select">
                            <option value="others" selected>Outros</option>
                            <option value="tech">Tecnologia</option>
                            <option value="industry">Indústria</option>
                            <option value="creativity">Criatividade</option>
                            <option value="education">Educação</option>
                            <option value="health">Saúde e Bem-Estar</option>
                            <option value="business">Negócios e Finanças</option>
                            <option value="entertainment">Artes e Entretenimento</option>
                            <option value="Sustainability">Sustentabilidade</option>
                            <option value="food">Alimentação e Gastronomia</option>
                            <option value="tourism">Viagens e Turismo</option>
                          </select>
                        </div>
                        <div class="mb-3">
                          <label for="youtube" class="form-label d-flex fw-bold">Youtube:</label>
                          <input type="text" v-model="formData.youtube" class="form-control">
                        </div>
                        <div class="mb-3">
                          <label for="instagram" class="form-label d-flex fw-bold">Instagram:</label>
                          <input type="text" v-model="formData.instagram" class="form-control">
                        </div>
                        <div class="mb-3">
                          <label for="instagram" class="form-label d-flex fw-bold">Instagram 2:</label>
                          <input type="text" v-model="formData.instagram_2" class="form-control">
                        </div>
                        <div class="mb-3">
                          <label for="telefone" class="form-label d-flex fw-bold">Telefone: (Ex: tel:+556230884876)</label>
                          <input type="text" v-model="formData.telefone" class="form-control">
                        </div>
                        <div class="mb-3">
                          <label for="telefone" class="form-label d-flex fw-bold">Telefone 2: (Ex: tel:+556230884876)</label>
                          <input type="text" v-model="formData.telefone_2" class="form-control">
                        </div>
                        <div class="mb-3">
                          <label for="facebook" class="form-label d-flex fw-bold">Facebook:</label>
                          <input type="text" v-model="formData.facebook" class="form-control">
                        </div>
                        <div class="mb-3">
                          <label for="telefone" class="form-label d-flex fw-bold">Whatsapp: (Ex: http://wa.me/55629XXXXXXXX)</label>
                          <input type="text" v-model="formData.whatsapp" class="form-control">
                        </div>
                        <div class="mb-3">
                          <label for="telefone" class="form-label d-flex fw-bold">Whatsapp 2: (Ex: http://wa.me/55629XXXXXXXX)</label>
                          <input type="text" v-model="formData.whatsapp_2" class="form-control">
                        </div>
                        <div class="mb-3">
                          <label for="website" class="form-label d-flex fw-bold">Website</label>
                          <input type="text" v-model="formData.website" class="form-control">
                        </div>
                        <div class="mb-3">
                          <label for="logo" class="form-label d-flex fw-bold">Logo:</label>
                          <input type="file" accept=".png, .jpg, .jpeg" @change="selectLogo" class="form-control">
                        </div>
                        <div class="mb-3">
                          <label for="logo" class="form-label d-flex fw-bold">Imagem 1:</label>
                          <input type="file" accept=".png, .jpg, .jpeg" @change="selectImage1" class="form-control">
                        </div>
                        <div class="mb-3">
                          <label for="logo" class="form-label d-flex fw-bold">Imagem 2:</label>
                          <input type="file" accept=".png, .jpg, .jpeg" @change="selectImage2" class="form-control">
                        </div>
                        <div class="mb-3">
                          <label for="promocoes" class="form-label d-flex fw-bold">Promoções:</label>
                          <input v-model="formData.promocoes" class="form-control">
                        </div>
                        <div class="mb-3">
                          <label for="descricao" class="form-label d-flex fw-bold">Descrição:</label>
                          <textarea v-model="formData.descricao" class="form-control" rows="10"></textarea>
                        </div>
                        <button type="submit" class="btn btn-primary"><i class="bi bi-plus me-2"></i>Inserir parceiro</button>
                      </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Loading v-if="isLoading"/>
</template>

<!-- eslint-disable no-console -->
<script>
import axios from 'axios';
import Loading from '../molecules/Loading.vue';

export default {
  data() {
    return {
      isLoading: false,
      showModal: false,
      formData: {
        id: '',
        nome: '',
        tag: '',
        youtube: '',
        instagram: '',
        instagram_2: '',
        telefone: '',
        telefone_2: '',
        facebook: '',
        whatsapp: '',
        whatsapp_2: '',
        website: '',
        promocoes: '',
        descricao: '',
        imagem_1: '',
        imagem_2: '',
        logoSelected: null,
        imagem_1_Selected: null,
        imagem_2_Selected: null,
      },
    };
  },
  emits: ['insertedPartner'],
  components: { Loading },
  methods: {
    hideModal() {
      this.showModal = false;
      this.formData.id = '';
      this.formData.nome = '';
      this.formData.tag = '';
      this.formData.youtube = '';
      this.formData.instagram = '';
      this.formData.instagram_2 = '';
      this.formData.telefone = '';
      this.formData.telefone_2 = '';
      this.formData.facebook = '';
      this.formData.whatsapp = '';
      this.formData.whatsapp_2 = '';
      this.formData.website = '';
      this.formData.promocoes = '';
      this.formData.descricao = '';
      this.formData.imagem_1 = '';
      this.formData.imagem_2 = '';
      this.formData.logoSelected = null;
      this.formData.imagem_1_Selected = null;
      this.formData.imagem_2_Selected = null;
    },
    showModalWithForm() {
      this.showModal = true;
    },
    selectLogo(logo) {
      // eslint-disable-next-line prefer-destructuring
      this.formData.logoSelected = logo.target.files[0];
    },
    selectImage1(img1) {
      // eslint-disable-next-line prefer-destructuring
      this.formData.imagem_1_Selected = img1.target.files[0];
    },
    selectImage2(img2) {
      // eslint-disable-next-line prefer-destructuring
      this.formData.imagem_2_Selected = img2.target.files[0];
    },
    insertPartner() {
      this.isLoading = true;
      const formData = new FormData();

      const dataToAppend = {
        nome: this.formData.nome,
        tag: this.formData.tag,
        facebook: this.formData.facebook,
        youtube: this.formData.youtube,
        instagram: this.formData.instagram,
        instagram_2: this.formData.instagram_2,
        telefone: this.formData.telefone,
        telefone_2: this.formData.telefone_2,
        whatsapp: this.formData.whatsapp,
        whatsapp_2: this.formData.whatsapp_2,
        website: this.formData.website,
        promocoes: this.formData.promocoes,
        descricao: this.formData.descricao,
        logo: this.formData.logoSelected,
        imagem_1: this.formData.imagem_1_Selected,
        imagem_2: this.formData.imagem_2_Selected,
      };

      const token = localStorage.getItem('token');
      if (token) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const key in dataToAppend) {
        if (dataToAppend[key] !== null && dataToAppend[key] !== '') {
          formData.append(key, dataToAppend[key]);
        }
      }

      axios.post('https://api.asemego.com.br/api/parceiros', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(() => {
          // Handle success
          this.formData = {
            id: '',
            nome: '',
            tag: '',
            youtube: '',
            instagram: '',
            instagram_2: '',
            telefone: '',
            telefone_2: '',
            facebook: '',
            whatsapp: '',
            whatsapp_2: '',
            website: '',
            promocoes: '',
            descricao: '',
            imagem_1: '',
            imagem_2: '',
            logoSelected: null,
            imagem_1_Selected: null,
            imagem_2_Selected: null,
          };

          // Limpe os campos de seleção de arquivo
          this.$refs.logoInput.value = null;
          this.$refs.image1Input.value = null;
          this.$refs.image2Input.value = null;
        })
        .catch(() => {
          // Handle error
        })
        .finally(() => {
          this.$emit('insertedPartner');
          this.isLoading = false;
        });
    },
  },
};
</script>
<!-- eslint-disable no-tabs -->
<style scoped>
.modal {
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal.show {
  display: block;
}

.modal-dialog {
  margin-top: 10%;
}

.modal-content {
  background-color: #fff;
}

.modal-header {
    background-color: #333342;
    color: #FFF;
    font-weight: bold;
}

.img-asemego {
    width: 240px;
}

.btn-primary {
  background-color: #333342 !important;
  border-color: #333342 !important;
}
</style>
