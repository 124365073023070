<!-- eslint-disable no-tabs -->
<!-- eslint-disable no-tabs -->

<template>
<div class="table-view">
	<div class="d-flex align-items-center h-100">
		<div class="container table-container">
			<div class="row justify-content-center w-100">
				<div class="col-12">
					<div class="table-responsive">
						<table class="table table-borderless text-white mb-0">
							<thead>
								<tr>
									<th v-for="column in columns" :key="column.field">{{ column.label }}</th>
								</tr>
							</thead>
							<tbody>
								<slot></slot>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
export default {
  name: 'TableComponent',
  props: {
    columns: {
      type: Array,
      required: true,
    },
  },
};
</script>

<!-- eslint-disable no-tabs -->
<style scoped>
.table-view {
	height: 100%;
}

.table-container {
	height: 500px;
	overflow-y: scroll;
}

table {
  width: 100%;
  background-color: #A89F8E;
  border-collapse: collapse;
  border-width: 1px;
  border-color: #333342;
  border-style: solid;
  color: #FFFFFF;
}

table td, table th {
  border-width: 1px;
  border-color: #333342;
  border-style: solid;
  padding: 5px;
}

table thead {
  background-color: #333342;
}
tr:hover {
	background-color: #ddd;
}
</style>
