<!-- eslint-disable max-len -->
<!-- eslint-disable no-tabs -->
<template>
  <div>
      <!-- Modal -->
      <div class="modal" tabindex="-1" role="dialog" :class="{ 'show': showModal }">
          <div class="modal-dialog" role="document">
              <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title" style="font-family: fantasy;"><i class="bi bi-person me-2"></i>EDITAR AGENDAMENTO</h5>
                      <button type="button" class="btn btn-danger" @click="hideModal">
                          <span aria-hidden="true">X</span>
                      </button>
                  </div>
                  <div class="modal-body">
                    <form @submit.prevent="editSchedule" class="d-flex flex-column justify-content-center">
                      <div class="mb-3">
                        <label for="nome" class="form-label d-flex fw-bold">Nome:</label>
                        <input type="text" v-model="formDataEdit.nome" class="form-control" required>
                      </div>
                      <div class="mb-3">
                        <label for="tag" class="form-label d-flex fw-bold">Tag:</label>
                        <select v-model="formDataEdit.tipo" class="form-select">
                          <option value="others" selected>Outros (others)</option>
                          <option value="health">Saúde e Bem-Estar (health)</option>
                          <option value="room">Sala (room)</option>
                          <option value="place">Espaço (place)</option>
                        </select>
                      </div>
                      <div class="mb-3">
                        <label for="telefone" class="form-label d-flex fw-bold">Telefone:</label>
                        <input type="text" v-model="formDataEdit.telefone" class="form-control">
                      </div>
                      <div class="mb-3">
                        <label for="telefone" class="form-label d-flex fw-bold">Whatsapp:</label>
                        <input type="text" v-model="formDataEdit.whatsapp" class="form-control">
                      </div>
                      <div class="mb-3">
                        <label for="logo" class="form-label d-flex fw-bold">Imagem:</label>
                        <input type="file" accept=".png, .jpg, .jpeg" @change="selectImage" class="form-control">
                      </div>
                      <div class="mb-3">
                        <label for="logo" class="form-label d-flex fw-bold">Imagem 2:</label>
                        <input type="file" accept=".png, .jpg, .jpeg" @change="selectImage2" class="form-control">
                      </div>
                      <div class="mb-3">
                        <label for="descricao" class="form-label d-flex fw-bold">Descrição:</label>
                        <textarea v-model="formDataEdit.descricao" class="form-control" rows="10"></textarea>
                      </div>
                      <div class="mb-3 w-100">
                        <img class="img-schedule" :src="`${this.link}${this.agendamento.id}/imagem/?number=1&cache=${cacheBuster}`">
                      </div>
                      <div class="mb-3 w-100">
                        <img class="img-schedule" :src="`${this.link}${this.agendamento.id}/imagem/?number=2&cache=${cacheBuster}`">
                      </div>
                      <button type="submit" class="btn btn-primary"><i class="bi bi-plus me-2"></i>Editar parceiro</button>
                    </form>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <Loading v-if="isLoading"/>
</template>

<!-- eslint-disable no-console -->
<script>
import axios from 'axios';
import Loading from '../molecules/Loading.vue';

export default {
  data() {
    return {
      isLoading: false,
      showModal: false,
      link: 'https://api.asemego.com.br/api/agendamentos/',
      cacheBuster: 0,
      formDataEdit: {
        nome: '',
        tipo: '',
        telefone: '',
        whatsapp: '',
        descricao: '',
        imagem_1: '',
        imagem_2: '',
        imagem_1_Selected: null,
        imagem_2_Selected: null,
      },
      tagOptions: [
        { value: 'others', label: 'Outros (others)' },
        { value: 'health', label: 'Saúde e Bem-Estar (health)' },
        { value: 'room', label: 'Sala (room)' },
        { value: 'place', label: 'Espaço (place)' },
      ],
    };
  },
  emits: ['edited'],
  props: {
    agendamento: {},
  },
  components: { Loading },
  beforeMount() {
    this.updateImage();
  },
  methods: {
    updateImage() {
      this.cacheBuster = Math.floor(Math.random(2) * 999); // Número aleatório entre 0 e 999
    },
    hideModal() {
      this.showModal = false;
    },
    showModalWithEditData(agendamentoSelecionado) {
      this.showModal = true;

      // Limpa os dados do formulário ao abrir a modal
      this.formDataEdit = {
        nome: '',
        tipo: '',
        telefone: '',
        whatsapp: '',
        descricao: '',
        imagem_1: '',
        imagem_1_Selected: null,
        imagem_2_Selected: null,
      };

      // Verifica se agendamentoSelecionado não é nulo ou indefinido antes de copiar os dados
      if (agendamentoSelecionado) {
        this.formDataEdit.nome = agendamentoSelecionado.nome || '';
        this.formDataEdit.tipo = agendamentoSelecionado.tipo || '';
        this.formDataEdit.telefone = agendamentoSelecionado.telefone || '';
        this.formDataEdit.whatsapp = agendamentoSelecionado.whatsapp || '';
        this.formDataEdit.descricao = agendamentoSelecionado.descricao || '';
        // Adicione o código para imagem se necessário
      }
    },
    selectImage(img) {
    // eslint-disable-next-line prefer-destructuring
      this.formDataEdit.imagem_1_Selected = img.target.files[0];
    },
    selectImage2(img2) {
    // eslint-disable-next-line prefer-destructuring
      this.formDataEdit.imagem_2_Selected = img2.target.files[0];
    },
    editSchedule() {
      this.isLoading = true;
      const formData = new FormData();

      const dataToAppend = {
        nome: this.formDataEdit.nome,
        tipo: this.formDataEdit.tipo,
        telefone: this.formDataEdit.telefone,
        whatsapp: this.formDataEdit.whatsapp,
        descricao: this.formDataEdit.descricao,
        imagem: this.formDataEdit.imagem_1_Selected,
        imagem_2: this.formDataEdit.imagem_2_Selected,
      };

      const token = localStorage.getItem('token');
      if (token) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      }

      // Adiciona os campos ao formData
      // eslint-disable-next-line no-restricted-syntax
      for (const key in dataToAppend) {
        if (dataToAppend[key] !== null && dataToAppend[key] !== '') {
          formData.append(key, dataToAppend[key]);
        }
      }

      axios.post(`https://api.asemego.com.br/api/agendamentos/update/${this.agendamento.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(() => {
          // Limpa os campos do formulário após o envio bem-sucedido
          this.formDataEdit = {
            nome: '',
            tipo: '',
            telefone: '',
            whatsapp: '',
            descricao: '',
            imagem: '',
            imagem_1_Selected: null,
            imagem_2_Selected: null,
          };

          // Limpa o campo de seleção de arquivo
          this.$refs.image1Input.value = null;
          this.$refs.image2Input.value = null;
        })
        .catch(() => {
          // Lida com o erro, se necessário
        })
        .finally(() => {
          this.$emit('edited');
          this.isLoading = false;
        });
    },
  },
};
</script>
<!-- eslint-disable no-tabs -->
<style scoped>
.modal {
display: none;
background-color: rgba(0, 0, 0, 0.5);
}

.modal.show {
display: block;
}

.modal-dialog {
margin-top: 10%;
}

.modal-content {
background-color: #fff;
}

.modal-header {
  background-color: #333342;
  color: #FFF;
  font-weight: bold;
}

.img-asemego {
  width: 240px;
}

.btn-primary {
background-color: #333342 !important;
border-color: #333342 !important;
}

.img-schedule {
  width: 100%;
  height: auto;
}

</style>
