/* eslint-disable eqeqeq */
/* eslint-disable no-lonely-if */
/* eslint-disable max-len */
import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import About from '../views/About.vue';
import PageLogin from '../views/PageLogin.vue';
import PageNews from '../views/PageNews.vue';
import PagePlans from '../views/PagePlans.vue';
import PartnersList from '../views/PartnersList.vue';
import PartnerDetails from '../views/PartnerDetails.vue';
import SchedulesList from '../views/SchedulesList.vue';
import SchedulesDetails from '../views/SchedulesDetails.vue';
import Dashboard from '../modules/admin/Dashboard.vue';
import Plans from '../modules/admin/pages/Plans.vue';
import Users from '../modules/admin/pages/Users.vue';
import Overall from '../modules/admin/pages/Overall.vue';
import PartnersAPI from '../modules/admin/pages/PartnersAPI.vue';
import Schedules from '../modules/admin/pages/Schedules.vue';
import NotFound from '../views/NotFound.vue';
import Bills from '../modules/admin/pages/Bills.vue';
import Account from '../modules/customers/Account.vue';
import Info from '../modules/customers/pages/Info.vue';
import PersonalBill from '../modules/customers/pages/PersonalBill.vue';
import RegistryCard from '../modules/customers/pages/RegistryCard.vue';
import TermsAndPolicy from '../views/TermsAndPolicy.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/terms',
    name: 'TermsAndPolicy',
    component: TermsAndPolicy,
  },
  {
    path: '/news',
    name: 'Novidades',
    component: PageNews,
  },
  {
    path: '/login',
    name: 'Login',
    component: PageLogin,
  },
  {
    path: '/plans',
    name: 'Planos',
    component: PagePlans,
  },
  {
    path: '/partners',
    name: 'PartnersList',
    component: PartnersList,
  },
  {
    path: '/partners/:partnerId',
    name: 'PartnerDetails',
    component: PartnerDetails,
    props: true,
  },
  {
    path: '/schedules',
    name: 'SchedulesList',
    component: SchedulesList,
  },
  {
    path: '/schedules/:id',
    name: 'SchedulesDetails',
    component: SchedulesDetails,
    props: true,
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    redirect: 'dashboard/overall',
    meta: { requiresRole: 56751 },
    component: Dashboard,
    children: [
      {
        path: 'users',
        component: Users,
      },
      {
        path: 'plans',
        component: Plans,
      },
      {
        path: 'overall',
        component: Overall,
      },
      {
        path: 'bills',
        component: Bills,
      },
      {
        path: 'partners-api',
        component: PartnersAPI,
      },
      {
        path: 'schedules',
        component: Schedules,
      },
    ],
  },
  {
    path: '/account',
    name: 'account',
    redirect: 'account/info',
    meta: { requiresRole: 1 },
    component: Account,
    children: [
      {
        path: 'info',
        component: Info,
      },
      {
        path: 'bills',
        component: PersonalBill,
      },
      {
        path: 'littlecard',
        component: RegistryCard,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const role = localStorage.getItem('role');
  if (to.meta.requiresRole) {
    const requiredRole = to.meta.requiresRole;
    if (role == requiredRole) {
      next();
    } else {
      next('/unauthorized');
    }
  } else {
    next();
  }
});

export default router;
