<!-- eslint-disable no-tabs -->

<template>
<div class="userdash-view mt-5">
  <h1 class="container text-start">Usuários:</h1>
  <table-component :columns="columns" class="text-start">
    <template v-slot:default>
      <tr v-for="(user, index) in users" :key="index">
        <td>{{ user.id }}</td>
        <td>{{ user.name }}</td>
        <td>{{ user.email }}</td>
        <td v-if="user.status == 'active'">
          <span>Ativo</span>
        </td>
        <td> {{ formatPhoneNumber(user.phones[0].number) }}</td>
      </tr>
    </template>
  </table-component>
  <Loading v-if="!isLoading"/>
</div>
</template>

<script>
import axios from 'axios';
import Loading from '@/components/molecules/Loading.vue';
import TableComponent from '../../../ui/TableComponent.vue';

export default {
  name: 'UsersListView',
  components: { Loading, TableComponent },
  data() {
    return {
      isLoading: false,
      columns: [
        { label: 'ID', field: 'id' },
        { label: 'Nome', field: 'name' },
        { label: 'E-mail', field: 'email' },
        { label: 'Status', field: 'status' },
        { label: 'Celular', field: 'phone' },
      ],
      users: [],
    };
  },
  beforeCreate() {
    this.isLoading = false;
    const token = localStorage.getItem('token');
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
    axios
      .get('https://api.asemego.com.br/api/customers', {})
      .then((res) => {
        this.users = res.data.customers;
      })
      .catch(() => {
      }).finally(() => {
        this.isLoading = true;
      });
  },
  methods: {
    formatPhoneNumber(data) {
      const areaCode = data.substring(2, 4);
      const prefix = data.substring(4, 8);
      const lineNumber = data.substring(8);
      const formattedPhoneNumber = `(${areaCode}) ${prefix}-${lineNumber}`;
      return formattedPhoneNumber;
    },
  },
};
</script>
<!-- eslint-disable no-tabs -->
<style scoped>
table td, table th {
  border-width: 1px;
  border-color: #333342;
  border-style: solid;
  padding: 5px;
}

tr:hover {
	background-color: red;
}
</style>
