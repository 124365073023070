<!-- eslint-disable max-len -->
<template>
<div class="header-component">
  <div style="background-color: rgba(51, 51, 66, 1); color: #FFF;" class="d-flex flex-row w-100 bar-desktop py-1">
      <div class="container text-start">
        <span class="mx-5">
          <i class="bi bi-telephone"></i>
          (62) 3624-6665
        </span>
        <span>
          <i class="bi bi-envelope"></i>
          contato@asemego.com.br
        </span>
      </div>
  </div>
  <nav class="navbar navbar-expand-md shadow-sm bg-white rounded">
    <div class="container">
      <router-link to="/">
        <img class="mx-5" src="../../assets/wp/logo.png" style="width: 150px; height: auto">
      </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarAsemego" aria-controls="navbarAsemego" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse" id="navbarAsemego">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link class="nav-link" to="/">HOME</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/about">SOBRE</router-link>
          </li>
          <li class="nav-item">
            <a
              href="https://asemegovantagens.com.br/portal/cadastro-clubes?id_clube=104&page=convenios"
              class="nav-link"
              target="_blank"
            >
              CLUBE DE VANTAGENS
            </a>
          </li>
          <li class="nav-item">
            <router-link to="/news" class="nav-link">NOVIDADES</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/partners" class="nav-link">CONVÊNIOS</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/schedules" class="nav-link">AGENDAMENTOS</router-link>
          </li>
          <li v-if='!logged' class="nav-item button-nav-mobile button-login-mobile">
            <router-link to="/login" class="nav-link">
              <i class="bi bi-person-fill"></i>
              LOGIN
            </router-link>
          </li>
          <li v-if='!logged' class="nav-item button-nav-mobile button-plan-mobile">
            <router-link to="/plans" class="nav-link">
              <i class="bi bi-bag-fill"></i>
              PLANOS
            </router-link>
          </li>
          <li v-if='logged && isAssociated' class="nav-item button-nav-mobile button-plan-mobile">
            <router-link to="/account/info" class="nav-link" style="color: white">
              <i class="bi bi-person-video2"></i>
              PAINEL
            </router-link>
          </li>
          <li v-if='logged && isAdmintrator' class="nav-item button-nav-mobile button-plan-mobile">
            <router-link to="/dashboard/overall" class="nav-link" style="color: white">
              <i class="bi bi-person-video2"></i>
              DASHBOARD
            </router-link>
          </li>
          <li v-if='logged' class="nav-item button-nav-mobile bg-danger">
            <a class="nav-link" style="color: white" @click="logout">
              <i class="bi bi-arrow-left-circle"></i>
              SAIR
            </a>
          </li>
        </ul>
      </div>
      <div v-if='logged && isAssociated' class="bar-desktop d-flex flex-row">
        <router-link class="btn btn-outline-success" to="/account/info">
          <i class="bi bi-person-video2"></i>
          Painel
        </router-link>
        <button v-if='logged' type="button" class="btn btn-outline-danger ms-2" @click="logout">Sair</button>
      </div>
      <div v-if='logged && isAdmintrator' class="bar-desktop d-flex flex-row">
        <router-link class="btn btn-outline-success" to="/dashboard/overall">
          <i class="bi bi-person-video2"></i>
          Dashboard
        </router-link>
        <button v-if='logged' type="button" class="btn btn-outline-danger ms-2" @click="logout">Sair</button>
      </div>
      <div v-if='!logged' class="bar-desktop d-flex flex-row">
        <router-link class="btn btn-success me-3" to="/login">
          <i class="bi bi-person-fill"></i>
          Login
        </router-link>
        <router-link class="btn btn-outline-secondary" to="/plans">
          <i class="bi bi-bag-fill"></i>
          Planos
        </router-link>
      </div>
    </div>
  </nav>
</div>
<Loading v-if="isLoading" />
</template>

<script>
import axios from 'axios';
import Loading from '../molecules/Loading.vue';
import { useAuthStore } from '../../store/store';

export default {
  components: { Loading },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    logged() {
      return useAuthStore().logged;
    },
    isAssociated() {
      return useAuthStore().isUser;
    },
    isAdmintrator() {
      return useAuthStore().isAdmin;
    },
  },

  mounted() {
    // Verifique o estado de autenticação no carregamento inicial da página
    const token = localStorage.getItem('token');
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      const role = localStorage.getItem('role');
      const authStore = useAuthStore();
      authStore.setLogged(true);

      // Defina as roles com base nas informações armazenadas no localStorage
      if (role === '56751') {
        authStore.setAdmin(true);
      } else if (role === '1') {
        authStore.setUser(true);
      }
    }
  },

  methods: {
    logout() {
      this.isLoading = true;
      const token = localStorage.getItem('token');
      if (token) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      }
      axios
        .post('https://api.asemego.com.br/api/logout')
        .then(() => {
          localStorage.removeItem('login');
          localStorage.removeItem('token');
          localStorage.removeItem('id');
          localStorage.removeItem('id_vindi');
          localStorage.removeItem('role');
          localStorage.removeItem('nome');
          localStorage.removeItem('carteirinha');
          sessionStorage.removeItem('userData');
          const authStore = useAuthStore();
          authStore.setLogged(false);
          authStore.setAdmin(false);
          authStore.setUser(false);
          this.$router.push('/');
        })
        .catch(() => {
          localStorage.removeItem('login');
          localStorage.removeItem('token');
          localStorage.removeItem('id');
          localStorage.removeItem('id_vindi');
          localStorage.removeItem('role');
          localStorage.removeItem('nome');
          localStorage.removeItem('carteirinha');
          sessionStorage.removeItem('userData');
          const authStore = useAuthStore();
          authStore.setLogged(false);
          authStore.setAdmin(false);
          authStore.setUser(false);
          this.$router.push('/');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.button-nav-mobile {
  display: none !important;
}

li {
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  .bar-desktop {
    display: none !important;
  }
  .button-nav-mobile {
    display: block !important;
  }

  .button-login-mobile {
    background-color: lightgreen;
  }

  .button-plan-mobile {
    background-color: lightseagreen;
  }
}
</style>
