<!-- eslint-disable max-len -->
<template>
<div class="home-view">
    <router-link class="img-slide-content" to="/plans">
      <div class="img-slide-desktop"><img class="w-100" src="../assets/wp/slide-one.jpg" /></div>
      <div class="img-slide-mobile w-100"><img class="w-100" src="../assets/wp/mobile.jpg" /></div>
    </router-link>
    <div class="about-us-content d-flex flex-row w-100 container my-4">
      <div class="about-us-text w-50 d-flex flex-column">
        <p class="text-start d-flex flex-row title-props"><span class="quem me-1"><strong>QUEM</strong></span><span class="somos"><strong>SOMOS</strong></span></p>
        <p class="text-start">A ASEMEGO é a Associação dos Servidores da Educação dos Municípios e do Estado de Goiás, formada por profissionais conhecedores da realidade e, acima de tudo, preocupados em oferecer aos servidores públicos um espaço inovador para atender às demandas individuais e coletivas da categoria.</p>
        <p class="text-start title-props"><strong>MISSÃO, VISÃO, VALORES</strong></p><span class="text-start mb-2">Saber quais são os princípios de uma organização antes de se associar a ela é fundamental. Por isso, confira quais são os principais fundamentos que compõem e, principalmente, que moldam a ASEMEGO.</span>
        <span class="text-start mb-2"><strong>Visão:</strong> A ASEMEGO é uma associação que tem como visão acolher os Servidores da Educação dos Municípios e do Estado de Goiás;</span>
        <span class="text-start mb-2"><strong>Missão:</strong> Ser reconhecida como uma parceira decisiva, trazendo confiança aos seus associados, proporcionando inúmeras vantagens, fortalecendo e estreitando os vínculos dos Servidores da Educação dos Municípios e do Estado de Goiás;</span>
        <span class="text-start"><strong>Valores:</strong> Igualdade, União, Parceria, Empatia e Respeito.</span>
        <router-link class="btn btn-secondary d-flex w-50 justify-content-center my-3 fw-bold" to="/plans">Seja um associado</router-link>
      </div>
        <div class="about-us-who w-50"><img class="w-100" src="../assets/wp/who-img.jpg" /></div>
    </div>
    <div class="associate-divider w-100 d-flex flex-column h-auto">
        <div class="text-center mt-4 mb-2 d-flex flex-column fw-bold"><span class="mb-1">Temos um espaço especial para você,</span><span>Servidor(a) Público(a) da educação!</span></div>
        <div class="d-flex flex-row justify-content-center mb-4">
          <router-link class="btn btn-success my-2 fw-bold" to="/plans">Seja um Associado</router-link>
        </div>
    </div>
    <div class="associate-advantages w-100 d-flex flex-column my-5">
        <p class="fw-bold">
          <span class="quem me-1">DIFERENCIAIS</span>
          <span class="somos">EXCLUSIVOS</span>
        </p>
        <span class="advantages-subtitle">Conheça as vantagens de ser um(a) associado(a) à ASEMEGO!</span>
        <div class="advantages-cards d-flex justify-content-center my-5">
          <div class="cards mx-4 my-3 d-flex flex-column justify-content-between">
            <a href="https://asemegovantagens.com.br/portal/cadastro-clubes?id_clube=104&amp;page=convenios" target="_blank">
              <div class="card-image">
                <img src="../assets/wp/icon.png" alt="logo asemego" />
              </div>
            </a>
            <div class="card-text my-2">
              <span class="fw-bold quem">Clube de vantagens</span>
            </div>
          </div>
          <div class="cards mx-4 my-3 d-flex flex-column justify-content-between">
            <a href="https://api.whatsapp.com/send/?phone=556236246665&amp;text&amp;type=phone_number&amp;app_absent=0" target="_blank">
              <div class="card-image">
                <img src="../assets/wp/icon.png" alt="logo asemego" />
              </div>
            </a>
            <div class="card-text my-2"><span class="fw-bold quem">Agendamentos para consultas</span></div>
          </div>
          <div class="cards mx-4 my-3 d-flex flex-column justify-content-between">
            <router-link to="/partners">
              <div class="card-image"><img src="../assets/wp/icon.png" alt="logo asemego" /></div>
              <div class="card-text my-2"><span class="fw-bold quem">Convênios exclusivos</span></div>
            </router-link>
          </div>
        </div>
    </div>
    <div class="associate-area w-100 d-flex">
        <div class="left-side w-50"><img class="w-100" src="../assets/wp/mulher-esquerda.jpg" alt="mulher feliz" /></div>
        <div class="right-side w-50 ps-3">
            <div class="title-associate-area d-flex flex-row mt-3"><span class="me-1 quem fw-bold">ÁREA DO</span><span class="somos fw-bold">ASSOCIADO</span></div>
            <h4 class="quem text-start my-3">Conheça os benefícios da Área do Associado</h4>
            <ul class="text-start">
              <li>Clube de vantagens e descontos em vários estabelecimentos;</li>
              <li>Assessoria jurídica em âmbito educacional;</li>
              <li>Coworking para auxiliar os diretores nas demandas externas;</li>
              <li>Espaço para divulgação dos empreendimentos dos servidores;</li>
              <li>Happy hour do servidor;</li>
              <li>Salas de reuniões;</li>
              <li>Promoção intelectual para os associados;</li>
              <li>Espaço de bem-estar e beleza;</li>
              <li>Espaço multiprofissional;</li>
              <li>Espaço gourmet e muito mais!</li>
            </ul>
        </div>
    </div>
</div>
<WhatsappButton />
</template>

<script>
import WhatsappButton from '../components/atoms/WhatsappButton.vue';

export default {
  name: 'Home',
  components: { WhatsappButton },
};
</script>

<style scoped>
.card-text {
  transition: color 0.3s ease;
}

.img-slide-mobile {
  display: none;
}

.about-us-who img {
  -webkit-box-shadow: 0px 1px 11px 5px rgba(0,0,0,0.36);
  -moz-box-shadow: 0px 1px 11px 5px rgba(0,0,0,0.36);
  box-shadow: 0px 1px 11px 5px rgba(0,0,0,0.36);
}

.cards {
  padding: 10px 10px 10px 10px;
  color: var(--primaria);
  cursor: pointer;
  border: none !important;
  -webkit-box-shadow: 0px 1px 11px 5px rgba(0,0,0,0.36);
  -moz-box-shadow: 0px 1px 11px 5px rgba(0,0,0,0.36);
  box-shadow: 0px 1px 11px 5px rgba(0,0,0,0.36);
  border-radius: 6px;
}

.cards a {
  text-decoration: none;
  color: unset;
}

.cards:hover {
  background-color: #333342;
  color: white !important;
}

.img-slide-desktop {
  display: block;
}
.title-props {
  font-size: 20px;
}

.somos {
  color: var(--secundaria);
}

.associate-divider {
  background-image: url("../assets/wp/bgbg.jpg");
}
.associate-divider .text-center span {
  font-size: 20px;
  color: #fff;
}

.advantages-subtitle {
  font-size: 20px;
  color: var(----primaria);
}

.associate-area .right-side {
  background-color: #d3d3d3;
}

@media screen and (max-width: 768px) {
  .img-slide-mobile {
    display: block;
	}

  .img-slide-desktop {
    display: none;
	}

  .about-us-content {
    display: flex;
    flex-direction: column !important;
    width: 100% !important;
	}

  .about-us-content .about-us-text {
    display: flex;
    flex-direction: row;
    width: 100% !important;
	}

  .about-us-content .about-us-text button {
    align-self: center;
	}

  .about-us-content .about-us-who {
    display: flex;
    width: 100% !important;
    flex-direction: row;
	}

  .associate-area {
    display: flex;
    flex-direction: column !important;
	}

  .associate-area .left-side {
    width: 100% !important;
	}

  .associate-area .right-side {
    width: 100% !important;
	}

  .advantages-cards {
    flex-direction: column !important;
	}
}
</style>
