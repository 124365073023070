<!-- eslint-disable max-len -->
<!-- eslint-disable no-tabs -->
<template>
    <div>
        <!-- Modal -->
        <div class="modal" tabindex="-1" role="dialog" :class="{ 'show': showModal }">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" style="font-family: fantasy;"><i class="bi bi-person-fill-dash me-2"></i>REMOVER AGENDAMENTO</h5>
                        <button type="button" class="btn btn-danger" @click="hideModal">
                            <span aria-hidden="true">X</span>
                        </button>
                    </div>
                    <div class="modal-body">
                      <h5>Deseja remover o agendamento <span class="fw-bold me-2" style="background-color: red; color: white;">{{ agendamento.nome }}</span>?</h5>
                      <div class="d-flex flex-row justify-content-center mt-4">
                        <button class="btn btn-danger me-4" @click="confirmDeleteSchedule">Remover</button>
                        <button class="btn btn-info" @click="hideModal">Cancelar</button>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Loading v-if="isLoading"/>
</template>

<!-- eslint-disable no-console -->
<script>
import axios from 'axios';
import Loading from '../molecules/Loading.vue';

export default {
  data() {
    return {
      showModal: false,
      isLoading: false,
    };
  },
  components: { Loading },
  emits: ['deleted'],
  props: {
    agendamento: {},
  },
  methods: {
    hideModal() {
      this.showModal = false;
    },
    showModalToDelete() {
      this.showModal = true;
    },
    confirmDeleteSchedule() {
      this.isLoading = true;
      const token = localStorage.getItem('token');
      if (token) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      }
      axios
        .delete(`https://api.asemego.com.br/api/agendamentos/${this.agendamento.id}`)
        .then(() => {})
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
          this.$emit('deleted', this.agendamento);
        });
    },
  },
};
</script>
<!-- eslint-disable no-tabs -->
<style scoped>
.modal {
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal.show {
  display: block;
}

.modal-dialog {
  margin-top: 10%;
}

.modal-content {
  background-color: #fff;
}

.modal-header {
    background-color: #333342;
    color: #FFF;
    font-weight: bold;
}

.img-asemego {
    width: 240px;
}

.btn-primary {
  background-color: #333342 !important;
  border-color: #333342 !important;
}
</style>
