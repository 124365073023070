<!-- eslint-disable no-tabs -->

<!-- eslint-disable max-len -->
<template>
<div class="not-found-view">
	<div class="container my-5">
		<div class="row">
			<div class="col-md-12 text-center">
				<img class="img-notfound" src="../assets/ui/not-found-page.png" alt="Página não encontrada">
				<h2 class="display-4">Oops! Página não encontrada</h2>
				<p class="lead">Desculpe, mas a página que você está procurando não existe. Verifique se o URL está correto.</p>
				<router-link to="/" class="btn btn-success btn-lg">Voltar para a página inicial</router-link>
			</div>
		</div>
	</div>
</div>
</template>

<!-- eslint-disable no-tabs -->
<style scoped>
.not-found-view {
	height: 60vh;
}

.img-notfound {
	width: 200px;
	height: 200px;
}
</style>
